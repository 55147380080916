﻿.brand-section {
  max-width: 1000px;
  margin: 40px auto;
  margin-bottom: 4em;
}

.brandS-title {
  text-align: center;
  text-transform: uppercase;  
  margin-bottom: 0.7em !important;
}

.brandS-btn {
    text-decoration: none;
    padding: 10px 24px;
    border: 2px solid #000;
    border-radius: 6px;    
    font-style: normal;
    color: $color-primary;
    font-size: 2em;
}

.brandS-text {
  font-size: 2.5em;
  margin-top: 40px;
}

.brandS-headline {
  font-size: 1.3em;
  line-height: 1.6;
}

.text-pad {
  padding: 0 50px !important;
}

.bgpink {
  background-color: #ffeff7;
}

.bs-bg-box.bgpink {
  background-color: #ffeff7;
}

.vid-con.bgpink {
  background-color: #ffeff7;
}

img.resource-imgs-bx3 {
  width: 100%;
}

.txt-title {
  font-weight: 700;
}

// ----------------

.brandS-mainImg {
  display: flex;
  justify-content: center;
  background-color: #4e4e4e1a;
  height: 450px;
}

.btm-h {
  height: 300px;
}

.brandS-logo {
  text-align: center;
  align-self: center;
  .logo {
    font-size: 3em;
    margin-bottom: 25px;
    color: #fff;
  }
}

.brandS-model-image {
  display: flex;
  justify-content: center;
  max-width: 1000px;
  margin: 4em auto;
  .bs-bg-box {
    position: relative;
    background-color: #4e4e4e1a;
    .box-img {
      position: relative;
      right: 20px;
      bottom: 20px;
      .bx-innerImg {
        position: relative;
      }
    }
  }
}
// Quotes

.brandS-hl1 {
  position: relative;
  .left-quote::before {
    content: open-quote;
    position: absolute;
    font-size: 9em;
    top: 50px;
    left: -20px;
  }
  .right-quote::after {
    content: close-quote;
    position: absolute;
    right: 0;
    font-size: 9em;
    right: -10px;
    bottom: -120px;
  }
}

.left-quote-color::before {
  color: #f2c6db;
  content: open-quote;
  position: absolute;
  font-size: 9em;
  top: 50px;
  left: -20px;
}

.right-quote-color::after {
  color: #f2c6db;
  content: close-quote;
  position: absolute;
  right: 0;
  font-size: 9em;
  right: -10px;
  bottom: -120px;
}

.brandS-hl2 {
  .hlt1 {
    margin-top: 50px;
    p.headline-text.t1 {
      padding-left: 50px;
    }
  }
  .hlt2 {
    margin-top: 50px;
    p.headline-text.t2 {
      padding-right: 50px;
    }
  }
}
// Box Section
.brandS-bgCon {
  background-color: #4e4e4e1a;
  .bs-res {
    max-width: 1000px;
    margin: 40px auto;
    padding: 80px 0 100px;
  }
}

.bx-center {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50%;
  height: 160px;
}

.bs-textBtn {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: 0;
}

.bs-btn-text {
  font-weight: 600;
  font-size: 1.2em;
}
.bs-resBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 65%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: 0;
}
.bs-resBtn a {
  border: 1px solid;
  padding: 3px 12px;
  border-radius: 8px;
}
.bs-resBtn.btnt {
  top: 68%;
}

section.brandS-bgCon.bgpink {
  background-color: #ffeff7;
  overflow: hidden;
}

// ------------------------------

.bs-textBtn.bs-bx3-center {
  right: 50px;
}

.video-section {
  .vid-con {
    padding-top: 20px;
    background-color: #4e4e4e1a;
    .video-placeholder {
      bottom: 20px;
      right: 20px;
      .video-placeholder__image {
        margin-top: 0px !important;
      }
    }
    .video-placeholder::before {
      padding-bottom: 45%;
    }
  }
}

@media (min-width: 992px) {
  .bs-textBtn.bs-bx3-center {
    top: 50px !important;
    left: 50px !important;
  }

  .mg-top {
    margin-top: 20px !important;
  }

  .bs-mgt-text {
    margin-top: 50px;
  }
}

@media (min-width: 768.02px) {
  .brandS-title {
    font-size: 3.8em !important;
  }
  .mg-btm {
    margin-bottom: 15px;
  }

  .bx3 {
    width: 25.33333% !important;
  }

  .posi-left {
    top: 50%;
    left: 12%;
  }

  .bx2 {
    width: 25%;
  }

  .posi-left2 {
    top: 50%;
    left: 25%;
    margin-top: 15px;
  }

  .posi-left3 {
    margin-top: 15px;
    top: 50%;
    left: 37%;
  }
}

@media (max-width: 991.98px) {
}

@media (max-width: 767.98px) {
  .posi-left3 {
    margin-top: 15px;
    left: 25%;
  }

  .posi-left2 {
    margin-top: 15px;
  }

  .srbx3 {
    margin-top: 15px;
    left: 23%;
  }

  .bx12 {
    width: 51%;
  }

  .mgTop {
    margin-top: 15px;
  }

  .brandS-mainImg {
    height: 250px;
  }

  .brandS-model-image {
    padding: 0 20px 0 30px;
    .bs-bg-box {
      .box-img {
        position: relative;
        right: 10px;
        bottom: 10px;
      }
    }
  }

  .brandS-hl1 {
    position: relative;
    .left-quote::before {
      content: open-quote;
      position: absolute;
      font-size: 5em;
      top: 55px;
      left: 10px;
    }
    .right-quote::after {
      content: close-quote;
      position: absolute;
      font-size: 5em;
      right: 10px;
      bottom: -70px;
    }
  }

  .brandS-hl2 {
    .hlt1 {
      p.headline-text.t1 {
        padding-left: 0px;
        font-size: 1.2em;
      }
    }
    .hlt2 {
      margin-top: 0px;
      .hltbxx1 {
        grid-column: 1/3;
      }
      .hltbx2 {
        grid-column: 1/3;
        grid-row: 1/1;
        margin-bottom: 20px;
      }
      p.headline-text.t2 {
        padding-right: 0px;
      }
    }
  }
  .hltg {
    .hltgbx1 {
      grid-column: 1/3;
    }
    .hltgbx2 {
      grid-column: 1/3;
      grid-row: 1/1;
      margin-bottom: 20px;
    }
  }

  .htlmg {
    margin-bottom: 20px;
  }

  .brandS-title {
    font-size: 2em !important;
    font-weight: 600 !important;
    padding: 10px 0 !important;
  }

  .brandS-headline {
    line-height: 1.4;
  }

  .brand-section {
    max-width: 1000px;
    margin: 40px auto;
    margin-bottom: 4em;
  }

  .brandS-btn {
    font-size: 1.2em;
  }
  .brandS-text {
    font-size: 1em;
  }

  .brandS-bgCon {
    background-color: #4e4e4e1a;
    overflow: hidden;
  }

  .video-section {
    padding: 0 20px 0 30px;
    .vid-con {
      background-color: #4e4e4e1a;
      .video-placeholder {
        bottom: 10px;
        right: 10px;
        .video-placeholder__image {
          margin-top: 0px;
        }
      }
      .video-placeholder::before {
        padding-bottom: 45%;
      }
    }
  }

  .resource.bs-res-top.sec-left {
    margin-bottom: 20px;
    position: relative;
    left: 0px;
  }

  .bs-textBtn.bs-bx3-center {
    font-size: 1.5em;
    text-align: center;
    top: 20px;
    right: 120px;
  }

  .brandS-hl2 .hlt2 {
    display: block;
    margin-top: 0;
  }

  .btm-h {
    height: 140px !important;
  }
}

@media (max-width: 575.98px) {
  .brandS-hl2 {
    .hlt1 {
      p.headline-text.t1 {
        padding-left: 0px;
        font-size: 1.2em;
      }
    }
    .hlt2 {
      display: grid;
      margin-top: 0px;
      .hltbxx1 {
        grid-column: 1/3;
      }
      .hltbx2 {
        grid-column: 1/3;
        grid-row: 1/1;
        margin-bottom: 20px;
      }
      p.headline-text.t2 {
        padding-right: 0px;
      }
    }
  }

  .brandS-bgCon {
    background-color: #4e4e4e1a;
    .bs-res {
      padding: 30px 0 20px;
    }
  }

  .brandS-hl2 .hlt2 {
    display: grid;
    margin-top: 0;
  }

  .bs-resBtn a {
    padding: 3px 5px;
    border-radius: 8px;
  }
}

///////////////////////
