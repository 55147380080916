/*--- Product Styles --- */
.product__name,
.header-wrap { 
  font-style: normal;
}

.product__name {
  font-size: 1em;  
}

.prod-info__name,
.prod-info__brand {
    color: $color-primary;
}

.header-wrap {
    h1 {
        font-weight: 700;
        font-family: $font-header;
        font-size: 1.25em;
    }
}

@media only screen and (min-width: 640px) {
  .header-wrap {
    font-size: 30px;
  }
}

.filters__link {
    color: $color-primary;
    font-style: normal;
    font-weight: 400;
}

.filters__header {
    font-family: $font-subheader;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    background-color: $black;
}

.for-price,
.for-price--large {
    color: $color-primary;
}

.accordion__toggle {
    font-family: $font-primary;
}

.list-link {
    color: $black;
}

.deal-sku__link {
    color: $color-emphasis;
}

.price__label--highlight {
    background-color: #e80602;
}