.protector-container {
	margin: 20px auto 0;
	padding: 60px;
	color: $black;
	text-align: center;

	& + br {
		display: none;
	}
}

.purple-text {
	color: $color-emphasis;
	font-weight: 700;
}

.protector-tagline {
	margin: 12px auto;
	/*font-family: 'acumin-pro-condensed', sans-serif;*/
	font-size: 46px;
	font-style: normal;
	font-weight: 700;
}

.protector-text {
	max-width: 1000px;
	margin: auto;
	margin-bottom: 30px;
	/*font-family: 'acumin-pro-condensed', sans-serif;*/
	font-size: 22px;
	font-style: normal;
	font-weight: 400;
}

@media screen and (max-width: 768px) {
	.protector-tagline {
		font-size: 26px;
	}

	.protector-text {
		width: auto;
	}
}
