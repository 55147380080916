@font-face {
    font-display: fallback;
    font-family: "Lato"; // regular
    font-style: normal;
    font-weight: 400;
    src: url("/content/themes/salonservices/fonts/lato-v24-latin-regular.woff2") format("woff2"), url("/content/themes/salonservices/fonts/lato-v24-latin-regular.woff") format("woff");
}

@font-face {
    font-display: fallback;
    font-family: "Lato"; // regular / italic
    font-style: italic;
    font-weight: 400;
    src: url("/content/themes/salonservices/fonts/lato-v24-latin-italic.woff2") format("woff2"), url("/content/themes/salonservices/fonts/lato-v24-latin-italic.woff") format("woff");
}

@font-face {
    font-display: fallback;
    font-family: "Lato"; // bold
    font-style: normal;
    font-weight: 700;
    src: url("/content/themes/salonservices/fonts/lato-v24-latin-700.woff2") format("woff2"), url("/content/themes/salonservices/fonts/lato-v24-latin-700.woff") format("woff");
}

@font-face {
    font-display: fallback;
    font-family: "Playfair Display"; // bold
    font-style: normal;
    font-weight: 700;
    src: url("/content/themes/salonservices/fonts/playfair-display-v30-latin-700.woff2") format("woff2"), url("/content/themes/salonservices/fonts/playfair-display-v30-latin-700.woff") format("woff");
}