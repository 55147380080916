.mobile-opt-in {
	margin: 5px 0 15px;
}

.mobile-opt-in__yesno {
	display: block;
	margin: 5px 0;
}

.mobile-opt-in__options {
	display: none;
	margin-top: 5px;
	padding-left: 1.5em;
}

.mobile-opt-in__option {
	display: block;
	margin-top: 5px;
}

.mobile-opt-in__options.is--active {
	display: block;
}

.register .mobile-opt-in {
	padding: 10px 0;
}
