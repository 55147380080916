﻿.ssbuckets {
  background: #000;
  padding: 60px 0;
  .ssbuckets-container {
    max-width: 1000px;
    margin: 0 auto;
    .ssBucket {
      .ssBucketsTitle {
        font-family: $font-primary;
        font-size: 28px;
        text-align: center;
        color: #fff;
        margin-top: 30px;
      }
    }
  }
}

.image-text__wrap {
  background: black;
  padding-top: 3px;
}

.img__bucket {
  padding: 0px;
}

.text__bucket {
  text-align: center;
  margin-top: 10%;

  .image-text__text {
    h2 {
      font-size: 45px;
      font-family: $font-header;
      font-weight: 700;
      color: white;
      margin-bottom: 30px;
    }

    p {
      font-size: 20px;
      margin-top: 25px;
      color: white;
    }
  }
}

/* Black Text White BG Button*/
.blkTextBgWhite-btn {
    position: relative;
    font-size: 1.3em;
    font-weight: 400;
    text-transform: uppercase;
    text-decoration: none;
    color: $color-primary;
    padding: 10px 5px;

    .btn-text {
        display: inline-block;
    }

    &:hover {
        font-weight: bold;
    }
}

.buckets-btn {
  position: relative;
  padding: 10px 30px;
  max-width: 220px;
  margin: 0 auto;

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: white;
    transform: skewX(-25deg);
  }
}

a.trending-btn.btnTwo {
  padding: 10px 15px;
}

@media (max-width: 680px) {
  .text__bucket {
    text-align: center;
    margin-top: 60px;
    margin-bottom: 60px;

    .image-text__text {
      h2 {
        font-size: 40px;
      }
      p {
        font-size: 16px;
        margin-top: 25px;
        color: white;
      }
    }
  }
  .buckets-btn {
    position: relative;
    padding: 10px 30px;
    margin-top: 30px;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background: white;
      transform: skewX(-25deg);
    }
  }

    .home-wrap .bar {
        font-size: 16px;

        > span {
            font-size: 36px;
        }
    }
}
