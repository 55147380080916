//========================================================
// Best Seller
//========================================================
@media only screen and (min-width: 1000px) {
    .slider--bestseller .bx-viewport {
        .slidewrap {
            overflow-y: visible;
        }

        .slidewrap a {
            display: block;
            text-align: center;
            text-decoration: none;

            &::after {
                content: "Shop Now";
                position: relative;
                top: -0.5em;
                display: inline-block;
                margin: 0 auto;
                padding: 10px 20px;
                font-size: 20px;
                font-style: normal;        
                text-align: center;
                text-decoration: none;
                text-transform: uppercase;
                opacity: 0;
                color: #fff;
                background-color: black;
                border: none;
                transition: opacity 0.4s, transform 0.4s;
                transform: translateY(0);
                white-space: nowrap;
            }

            .slider-caption {
                transition: opacity 0.3s;
            }
        }

        .slidewrap a:hover {
            &::after {
                opacity: 1;
                text-decoration: none;
                transform: translateY(-0.5em);
            }

            .slider-caption {
                opacity: 0;
                text-decoration: none;
            }
        }
    }

    .slider--bestseller .bx-wrapper {
        margin-bottom: 15px;
    }

    .slider-caption {
        font-size: 15px;        
        color: $color-primary;
        font-family: $font-primary;
    }

  // ========================================================
  // Featured
  // ========================================================

  .slider--featured .bx-viewport .slidewrap a:after {
    content: "SHOP NOW";
    position: relative;
    top: auto;
    left: 5px !important;
    bottom: 30px !important;
    right: 0;
    padding: 10px 20px;
    font-size: 20px;    
    text-align: center;
    color: #fff;
    background-color: black;
    border: none;
    font-style: normal !important;
  }
  
  // Blog Button
    .home-article {
        a:after {
            content: none;
        }

        .home-article__link {
            position: relative;
        }

        .home-article__text {
            opacity: 1;
            transition: opacity 0.5s;
            margin-top: -20px;
        }

        .article--animation:after {
            content: "READ HERE";
            display: inline-block;
            position: relative;
            padding: 10px 20px;
            transition: bottom 0.5s, opacity 0.5s;
            text-align: center;
            font-size: 20px;
            color: #fff;
            background-color: black;
            border: none;
            top: 0.5em;
            left: 50%;
            transform: translate(-50%, 20%);
            opacity: 0;
        }

        &:hover {
            .article--animation:after {
                top: auto;
                bottom: 0;
                opacity: 1;
            }

            .home-article__text {
                opacity: 0;
            }
        }
    }
}

@media only screen and (max-width: 499px) {
    .home-article .article--animation:after {
        content: none;
    }
}
