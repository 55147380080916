.site-nav {
  background-color: #fff;
  border-right: 1px solid #a39fa0;
  border-bottom: none;
  font-family: $font-subheader;
  font-weight: 700;
}

.sub-nav a {
  font-family: $font-primary;  
}

@media only screen and (min-width: 820px) {
    .site-nav {
        background: none;
        border-color: #a39fa0;
        border-width: 0 0 1px 0;
        border-style: solid;
        font-size: 15px;
    }

    .site-nav__item--featured .site-nav__top {
        color: #fff;
        background-color: $pink;
    }

    .site-nav__item--holiday .site-nav__top {
        color: #fff;
        background-color: $color-emphasis;
    }  

  .page--default .site-nav {
    border-bottom: none;
  }

  .site-nav__list {
    margin: 0;
    text-align: left;
  }

  .site-nav__top {
    margin: 0px 4px 0 0;
    color: #fff;
    padding: 10px 10px;    
    border-radius: 0;         
  }

  .site-nav__item:hover .site-nav__top,
  .faux-hover .site-nav__top {
    color: #fff;
    background: $pink;
  }

  .sub-nav {
    min-width: 200px;
    padding: 0;
    margin-top: 0;
    background: #fff;
    border-top: 2px solid $pink;
    border-radius: 0;
  }

  .sub-nav__inner {
    padding: 0 15px;
    border-radius: 0;
    border: 1px solid #ddd;
    border-top: none;
  }

  .sub-nav a {
    display: block;
    padding: 8px 5px;
    font-size: 1.15em;
    text-transform: uppercase;
    font-weight: 400;
  }

  .sub-nav .sub-nav__back {
    display: none;
  }

    .sub-nav--big .sub-nav__col {
        max-height: 540px;
    }
}

.sub-nav__col {
    width: 208px;
}

@media only screen and (min-width: 1000px) {
    .sub-nav--big {
        max-width: 1000px;
    }
}

@media only screen and (min-width: 1250px) {
  .site-nav {
      max-width: 1200px;
  }

    .site-nav__top {
        padding: 10px 15px;
    }
}

@media only screen and (min-width: 1400px) {
    .sub-nav--big {
        max-width: 1250px;

        .sub-nav__col {
            max-height: 500px;
        }
    }
}

//------- global mobile changes ------//
.menu-wrap {
    background-color: #000;

    .search {
        display: none;
    }
}

.nav-toggles,
.nav-toggle {
    background-color: #fff;
    justify-self: center;
}

.burger {
    width: 30px;
    height: 23px;
}

.burger__middle {
    background: #000;
    top: 10px;
}

.burger::before,
.burger::after {
    background: #000;
}

@media only screen and (max-width: 620px) {
    .menu-wrap {
        border: 0px;
        display: grid;
        grid-template-columns: 1fr 4fr;
        padding: 10px 0;
        background-color: #fff;

        .search {
            display: flex;
        }
    }
}
