/* video gallery */
.videos {
	padding-top: 45px;
}

.video-thumb {
	position: relative;
}

.video-thumb__teaser {
	color: #333;
}

.video-thumb__image {
	display: block;
	width: 100%;
	height: auto;
}

.video-categories {
	padding-top: 45px;
	text-align: center;
}

.video-category__link {
	padding: 10px 20px;
	color: #414042;
	font-size: 16px;
	text-decoration: none;
	text-transform: lowercase;
	border: 1px solid #414042;
}

.video-category__link--active {
	color: #fff;
	background: #414042;
}

.video-category__link:hover {
	text-decoration: none;
}

.video-thumb__link:hover {
	text-decoration: none;
}

.video-thumb__link:hover .video-thumb__title {
	text-decoration: underline;
}

.video-thumb__link:hover .video-thumb__image, .video-thumb--active .video-thumb__image {
	filter: grayscale(0);
}

.video-thumb__title {
	margin-top: 10px;
	margin-bottom: 5px;
	padding: 0;
    font-size: 1.35em;
    color: #000;
}

@media only screen and (min-width: 800px) {
	.videos__thumbs {
		margin: 0 -15px;
		padding: 30px 0;
		overflow: hidden;
	}

	.video-thumb {
		float: left;
		width: 33.3333333%;
		padding: 0 15px;
        margin-bottom: 30px;
        height: 250px;
    }
    
    .video-thumb__image {
        height: 176px;
    }

	.video-thumb:nth-child(3n+1) {
		clear: both;
	}

	.video-thumb__teaser {
		right: 15px;
		left: 15px;
	}
}

@media (max-width: 768px) {
	.video-categories {
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
	}

	.video-category {
		margin: 20px 0;
	}
}