/* fonts */
@font-face {
  font-display: fallback;
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  src: url("/content/themes/salonservices/fonts/lato-v24-latin-regular.woff2") format("woff2"), url("/content/themes/salonservices/fonts/lato-v24-latin-regular.woff") format("woff");
}

@font-face {
  font-display: fallback;
  font-family: "Lato";
  font-style: italic;
  font-weight: 400;
  src: url("/content/themes/salonservices/fonts/lato-v24-latin-italic.woff2") format("woff2"), url("/content/themes/salonservices/fonts/lato-v24-latin-italic.woff") format("woff");
}

@font-face {
  font-display: fallback;
  font-family: "Lato";
  font-style: normal;
  font-weight: 700;
  src: url("/content/themes/salonservices/fonts/lato-v24-latin-700.woff2") format("woff2"), url("/content/themes/salonservices/fonts/lato-v24-latin-700.woff") format("woff");
}

@font-face {
  font-display: fallback;
  font-family: "Playfair Display";
  font-style: normal;
  font-weight: 700;
  src: url("/content/themes/salonservices/fonts/playfair-display-v30-latin-700.woff2") format("woff2"), url("/content/themes/salonservices/fonts/playfair-display-v30-latin-700.woff") format("woff");
}

/* colors */
body {
  font-size: 14px;
  font-family: "Lato", sans-serif;
  font-weight: 400;
  color: #000;
}

a {
  color: #5970f6;
}

img {
  max-width: 100%;
  height: auto;
}

.main-content {
  max-width: none;
}

.main-content__inner > div {
  max-width: 1000px;
  margin: 0 auto;
}

.page--default .main-content__inner > div,
.page--landing-default .main-content__inner > div {
  max-width: none;
}

.has--pencil .pencil,
.has--pencil .pencil-multi {
  max-width: none;
}

.pencil-banner {
  max-width: none;
}

.breadcrumb__help p,
.page-help p {
  display: none;
}

.page-header {
  overflow: initial;
}

/* ==== OBJECTS ==== */
/* ---------------------------
#buttons
--------------------------- */
.button {
  color: #fff;
  background: #000;
}

.RadGrid_Silk .rgEditForm .button {
  color: #fff;
}

.button--line {
  color: #7e7e7e;
  background: #fff;
}

.button--line:hover {
  color: #7e7e7e;
  background: #eee;
}

.button--text {
  color: #005ca0;
  background: none;
  border: none;
}

.button--text:hover {
  color: #005ca0;
  background: none;
  text-decoration: underline;
}

.button--quickview {
  background: #000;
  color: #fff;
}

.button--pending::after {
  background-color: #000;
}

.button__wrapper {
  position: relative;
  padding: 10px;
  max-width: 220px;
  margin: 0 auto;
  text-align: center;
}

.button__wrapper::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: #000;
  transform: skewX(-25deg);
}

.button__link {
  position: relative;
  font-size: 1.3em;
  font-weight: 400;
  text-transform: uppercase;
  text-decoration: none;
  color: #fff;
  padding: 10px 30px;
}

.button__link:hover {
  font-weight: 700;
}

/* ==== BANNERS ==== */
.standard-banners--secondary .standard-banner__button {
  position: relative;
  padding: 10px 30px;
  left: 0;
  max-width: 235px;
  margin: 30px auto 0;
  text-align: center;
}

.standard-banner__button-text {
  font-weight: 400;
}

.standard-banner__button-text:hover {
  font-weight: bold;
}

.standard-banners--secondary {
  background: #000;
  padding-top: 60px;
  padding-bottom: 60px;
}

.standard-banners--secondary .standard-banners--inner {
  max-width: 1000px;
  margin: 0 auto;
}

.standard-banners--secondary .standard-banner__title {
  color: #fff;
  text-align: center;
  font-size: 28px;
}

.standard-banners--secondary .standard-banner {
  margin: 0 auto;
  padding: 30px 0 30px 10px;
}

.standard-banners--secondary .standard-banner__title h3 {
  font-family: "Lato", sans-serif;
  font-weight: 400;
  font-size: 20px;
  text-align: center;
  color: #fff;
  margin-top: 30px;
}

.standard-banners--secondary .standard-banner__button:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: #fff;
  -webkit-transform: skewX(-25deg);
  -ms-transform: skewX(-25deg);
  transform: skewX(-25deg);
}

.standard-banners--secondary .standard-banner__button a {
  position: relative;
  font-size: 1.3em;
  font-weight: 300;
  text-transform: uppercase;
  text-decoration: none;
  color: #000;
  padding: 10px 5px;
}

@media only screen and (max-width: 680px) {
  .standard-banner__image {
    max-width: 80%;
  }
}

/* ---------------------------
#home-page
--------------------------- */
.bx-wrapper .bx-next,
.bx-wrapper .bx-prev {
  background: url("/content/themes/salonservices/images/universal-nav-arrows.png");
  width: 36px;
  height: 37px;
}

.bx-wrapper .bx-next {
  background-position: -36px 0;
}

.bx-wrapper .bx-next:hover {
  background-position: -36px 0;
}

.bx-wrapper .bx-controls-direction a {
  width: 36px;
  height: 37px;
  opacity: 0.8;
  z-index: 900;
  top: 40%;
}

.bx-wrapper .bx-pager.bx-default-pager a {
  background: #cac8c9;
}

.main-banner-wrap .bx-wrapper {
  margin-bottom: 0px;
}

.main-banner-wrap .bx-wrapper .bx-next {
  display: block;
  right: 5px;
}

.main-banner-wrap .bx-wrapper .bx-prev {
  display: block;
  left: 5px;
}

.main-banner-wrap .bx-controls-direction {
  display: block;
}

.main-banner-wrap .bx-controls-direction a {
  top: 50%;
}

.bx-wrapper .bx-pager.bx-default-pager a.active,
.bx-wrapper .bx-pager.bx-default-pager a:hover {
  background: #5970f6;
}

@media only screen and (max-width: 680px) {
  .standard-banners--secondary .standard-banner__button a {
    font-size: 1em;
  }
  .main-banner-wrap .bx-controls-direction {
    display: none;
  }
}

/* ==== 4: COMPONENTS ==== */
.home-site,
.catalogs {
  max-width: 1000px;
  margin-right: auto;
  margin-left: auto;
}

/* ---------------------------
#tabs
--------------------------- */
.tabs-nav .tabs-nav--active {
  background: #89b8aa;
  border-color: #89b8aa;
}

/* ---------------------------
#catalogs
--------------------------- */
.catalogs {
  margin-bottom: 45px;
}

.catalogs__header {
  padding-left: 0;
}

.catalog--primary .catalog__text,
.catalog--secondary .catalog__text {
  color: #980539;
}

.catalog--primary .catalog__text::after,
.catalog--secondary .catalog__text::after {
  background-image: url("/content/themes/salonservices/images/arrow-red.png");
}

/* ---------------------------
#countdown
--------------------------- */
.countdown {
  display: table;
  margin: 0 auto;
  padding: 0 30px 0 15px;
  color: #fff;
  overflow: hidden;
}

.countdown__section {
  float: left;
  margin: 0 2px;
  padding: 5px 2px;
  text-align: center;
}

.countdown__label {
  color: #ccc;
  color: rgba(255, 255, 255, 0.7);
  font-size: 0.8em;
  font-style: italic;
}

.countdown__text {
  display: inline-block;
  padding: 0 0 0 4px;
  font-size: 1.25em;
}

@media only screen and (min-width: 380px) {
  .countdown__text {
    padding-top: 8px;
  }
}

@media only screen and (min-width: 700px) {
  .countdown {
    float: left;
  }
  .countdown__section {
    margin: 0 10px;
    padding: 5px 10px;
  }
}

.icon--store {
  display: inline-block;
  width: 28px;
  height: 24px;
  vertical-align: middle;
  background-image: url("/content/themes/salonservices/images/store-pickup2.png");
  background-repeat: no-repeat;
  background-size: 100%;
  opacity: 0.9;
}

.store-popup__header {
  border-bottom: 5px solid #000;
}

.store-popup__box {
  border: 2px solid #000;
}

.store-popup__close {
  color: #000;
}

.pickup-store--selected {
  color: #000;
  background-color: rgba(234, 99, 145, 0.2);
}

/*--- Quick view Fix ---*/
.ui-dialog .g-4 {
  width: 33.333333%;
  margin: 0 auto;
}

/*--- End Quick view Fix ---*/
.home-social__inner {
  text-align: center;
  margin-bottom: 40px;
}

.home-social__inner span {
  color: #000;
  font-size: 45px;
  font-family: "Playfair Display", sans-serif;
  font-style: normal;
  font-weight: 700;
  text-align: center;
  text-transform: capitalize;
}

ul.home-social__list {
  display: inline-flex;
}

.home-social__item .i--facebook,
.home-social__item .i--tiktok,
.home-social__item .i--instagram {
  margin-right: 12px;
  width: 54px;
  height: 54px;
  background: url("/images/socialicons-home-black.png") no-repeat 0 0;
  float: left;
}

.home-social__item .i--instagram {
  background-position: -145px 0;
}

.home-social__item .i--facebook {
  background-position: -72px 0;
}

/* Multi Color Bar*/
.multiColorBar__bar {
  background: #fd0d0a;
  background: linear-gradient(to right, #fd0d0a 0%, #fd0065 21%, #ff00fe 41%, #5970f6 63%, #00fa92 100%);
  border: none;
}

.multiColorBar__text {
  font-weight: 400;
}

#Topic a {
  color: #5970f6;
}

/*--- Footer styles ---*/
.site-footer {
  margin-top: auto;
  padding-top: 0;
  background-color: black;
}

.site-footer .g-4 {
  margin: 0 20px;
  width: auto;
}

.site-footer .g-12 {
  padding: 0;
}

.site-footer .google-translate {
  padding: 5px 0;
}

.footer-signup {
  background: #fd0065;
  padding: 50px 0;
  margin-top: 0;
}

.site-footer__header {
  border-bottom: none;
}

.site-footer,
.site-footer a {
  max-width: 100%;
  padding: 0;
  color: #fff;
}

.site-footer a {
  font-size: 1.1em;
}

.site-footer__copyright {
  margin-top: 0;
  color: #fff;
  font-style: normal;
}

.site-footer__copyright small {
  font-size: 13px;
}

.site-footer__logo {
  padding-top: 60px;
}

.site-footer__panel h3 {
  font-size: 1.3em;
}

@media only screen and (min-width: 620px) {
  .site-footer__logo {
    padding-top: 80px;
  }
}

.site-footer__item .social.list--plain {
  margin-top: 4px;
}

.social__item {
  margin: auto;
  margin-right: 10px;
}

.social__item:hover .social__link:first-child {
  margin-top: unset;
}

.i--bangstyle,
.i--facebook,
.i--feed,
.i--instagram,
.i--linkedin,
.i--pinterest,
.i--twitter,
.i--wordpress,
.i--youtube,
.i--tiktok,
.i--email {
  background: url("/content/themes/salonservices/images/social-icons-white.png") no-repeat;
  background-size: 157px;
}

.i--facebook {
  background-position: -45px -2px;
}

.i--instagram {
  background-position: -87px -2px;
}

.i--email {
  background-position: -129px -2px;
}

.i--tiktok {
  background-position: -2px -2px;
}

.footer-inner {
  max-width: 1000px;
  margin: 0 auto;
  padding-top: 50px;
}

.footer-copyright {
  display: block;
  margin: 50px auto 0;
  width: 100%;
  background: #fd0d0a;
  background: linear-gradient(to right, #fd0d0a 0%, #fd0065 21%, #ff00fe 41%, #5970f6 63%, #00fa92 100%);
}

@media (max-width: 768px) {
  .site-footer__item .social.list--plain {
    margin-top: 10px;
  }
  .mobile-display {
    display: block;
  }
  .footer-inner {
    padding: 0 10px;
  }
  .footer-column {
    display: grid !important;
    grid-template-columns: auto auto;
  }
  .foot1 {
    grid-column: 1;
    grid-row: 1;
  }
  .foot2 {
    grid-column: 2;
    grid-row: 1 / span 2;
  }
  .site-footer__panel {
    padding-top: 15px;
  }
  .site-footer__panel h3 {
    font-size: 1em;
  }
  .site-footer {
    margin-top: auto;
  }
}

.site-header {
  background-color: black;
}

.top-header {
  font-size: 15px;
  background: #fd0d0a;
  background: linear-gradient(to right, #fd0d0a 0%, #fd0065 21%, #ff00fe 41%, #5970f6 63%, #00fa92 100%);
}

.top-header__inner {
  display: grid;
  grid-template-columns: 1fr auto;
  max-width: 1600px;
  margin: 0 auto;
  align-items: center;
}

.top-header__inner a {
  color: #000;
}

.header-top__note {
  display: none;
}

.header-top__note--mobile {
  display: none;
}

.header-top__assistance,
.header-top__assistance--mobile {
  display: none;
}

.header-top__locator {
  margin-left: 8px;
  grid-row: 2 / span 2;
  grid-column: 1;
}

.is--logged-in .header-top__locator {
  grid-row: 2;
  padding: 5px 0;
}

.shop-location {
  margin-left: 8px;
  padding-bottom: 8px;
  grid-row: 3;
  grid-column: 1;
}

.shop-location__content {
  color: #000;
}

.utility-bar {
  grid-row: 2 / span 2;
  grid-column: 2;
  align-self: center;
  background-color: transparent;
}

.utility-bar__inner {
  grid-template-columns: 1fr auto auto;
  max-width: 1600px;
  align-items: center;
}

.utility-bar__inner a {
  text-transform: uppercase;
  color: #000;
}

.utility-bar__inner .account-header__link {
  color: #fff;
}

.utility-bar__inner .login-links__link {
  font-weight: 700;
}

.login-links {
  grid-column: 2;
  text-transform: uppercase;
}

.account-header {
  grid-column: 2;
  background-color: transparent;
  color: #000;
}

.account-header__icon {
  display: block;
  justify-self: right;
  grid-row: 1;
}

.account-header__toggle {
  display: block;
  justify-self: left;
}

.account-header__icon .icon-person .icon-circle {
  fill: none;
  stroke: #000;
  stroke-width: 1px;
}

.account-header__icon .icon-person .icon-body,
.account-header__icon .icon-person .icon-head,
.account-header__toggle {
  fill: #000;
  stroke: #000;
}

.account-header__toggle svg,
.header-cart__icon svg {
  fill: #000;
}

.header-cart__value {
  color: #fff;
}

.account-header__nav {
  background-color: #f5f5f5;
}

.account-header__nav a {
  color: #000;
}

.account-header__list {
  background-color: #58595b;
}

.header-cart {
  grid-column: 3;
}

.login-links,
.header-cart,
.account-header__account-name,
.header-top__countdown {
  margin-right: 8px;
  color: #000;
}

.header-search {
  grid-column: 2;
  width: 100%;
  max-width: 400px;
  justify-self: center;
}

.search__options {
  display: none;
}

.search,
.search__text {
  max-width: unset;
}

.search {
  border-color: #4c4c4c;
  border-radius: 50px;
}

.search__text {
  border-left: none;
  padding: 0 20px;
}

.search__field {
  height: 35px;
  padding: 10px 10px 7px;
}

.search__field::placeholder {
  color: #000;
  text-transform: uppercase;
  font-size: 15px;
  line-height: 20px;
}

.search__submit {
  flex: 0 0 25px;
}

.button--search {
  width: 20px;
  height: 20px;
  background-size: 20px 20px;
  background: url("/content/themes/salonservices/images/search-black.png") no-repeat 0 0;
}

.header-top__countdown {
  grid-row: 1;
  grid-column: 2;
  width: 100%;
  padding-top: 5px;
  padding-right: 10px;
  max-width: 1600px;
  margin: 0 auto;
}

.header-top--added {
  background-color: #338326;
}

.site-logo {
  grid-column: 1;
  justify-self: start;
}

.site-logo__img {
  width: 300px;
}

.site-logo--mobile {
  display: none;
}

.header-banner {
  display: grid;
  align-items: center;
  max-width: 1600px;
  padding: 10px;
  background-color: #000;
}

.header-banner__assistance {
  justify-self: right;
}

.header-top__assistance--mobile a,
.header-banner__assistance a {
  color: #000;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
}

.account-header__close {
  color: inherit;
}

.site-header .google-translate {
  max-width: 1600px;
  padding-left: 5px;
}

@media only screen and (min-width: 680.02px) {
  .header-banner {
    grid-template-columns: 3fr 1fr;
  }
}

@media only screen and (min-width: 820px) {
  .top-header {
    z-index: 9000;
  }
  .title-wrap {
    padding: 0em;
    padding-bottom: 1em;
  }
  .account-header__nav {
    border-top: 1px solid white;
    box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.16);
  }
  .header-top__note {
    font-size: 15px;
  }
  .site-header .google-translate {
    padding-top: 45px;
  }
  .is--logged-in .site-header .google-translate {
    padding-top: 70px;
  }
}

@media only screen and (min-width: 820px) and (min-height: 600px) {
  .fixed-header {
    position: sticky;
    top: 0;
    z-index: 1000;
    background-color: #fff;
  }
}

@media only screen and (max-width: 620px) {
  .site-header {
    background: #fff;
  }
  .top-header__inner {
    grid-template-columns: 1fr;
  }
  .header-top__locator {
    margin-left: 8px;
    grid-row: 2;
    text-align: center;
    padding: 10px 0;
  }
  .shop-location {
    margin: 0 auto;
    padding-bottom: 0;
    font-size: 12px;
  }
  .shop-location__message {
    padding: 5px 10px;
  }
  .shop-location__content {
    text-align: left;
  }
  .header-top__countdown {
    grid-row: 5;
    grid-column: 1;
    text-align: center;
    padding: 5px 0;
  }
  .site-logo--mobile {
    grid-row: 6;
    padding: 25px;
    margin: 0 auto;
    display: block;
    grid-column: 1;
    background: #000;
    width: 100%;
    text-align: center;
  }
  .site-logo--mobile .site-logo__img {
    margin: 0 auto;
  }
  .utility-bar {
    grid-row: 7;
    grid-column: 1;
    background: #fff;
    padding: 0px;
  }
  .utility-bar__inner {
    grid-template-columns: 80% 20%;
    justify-items: center;
  }
  .account-header {
    width: 100%;
    grid-column: 1;
    color: #000;
    background-color: #fff;
  }
  .header-cart {
    grid-column: 2;
    justify-self: end;
  }
  .account-header__account-greeting {
    display: none;
  }
  .account-header__header-i-guess {
    display: grid;
    grid-template-columns: auto auto 75%;
    align-items: center;
  }
  .account-header__icon .icon-person {
    grid-column: 1;
    grid-row: 1;
    /*        .icon-circle,
        .icon-body,
        .icon-head {
            fill: #fff;
        }*/
  }
  .account-header__toggle {
    grid-column: 2;
    grid-row: 1;
    padding: 5px 10px 0 5px;
  }
  .account-header__title {
    grid-column: 3;
    text-align: left;
  }
  .search {
    display: none;
  }
  .login-links {
    grid-column: 1;
    justify-self: start;
    padding-left: 20px;
  }
  .header-search {
    padding-right: 10px;
    width: 100%;
    grid-column: 2;
  }
  .header-banner {
    display: none;
  }
  .title-wrap,
  .is--logged-in .title-wrap {
    padding: 0px;
  }
}

.ssbuckets {
  background: #000;
  padding: 60px 0;
}

.ssbuckets .ssbuckets-container {
  max-width: 1000px;
  margin: 0 auto;
}

.ssbuckets .ssbuckets-container .ssBucket .ssBucketsTitle {
  font-family: "Lato", sans-serif;
  font-size: 28px;
  text-align: center;
  color: #fff;
  margin-top: 30px;
}

.image-text__wrap {
  background: black;
  padding-top: 3px;
}

.img__bucket {
  padding: 0px;
}

.text__bucket {
  text-align: center;
  margin-top: 10%;
}

.text__bucket .image-text__text h2 {
  font-size: 45px;
  font-family: "Playfair Display", sans-serif;
  font-weight: 700;
  color: white;
  margin-bottom: 30px;
}

.text__bucket .image-text__text p {
  font-size: 20px;
  margin-top: 25px;
  color: white;
}

/* Black Text White BG Button*/
.blkTextBgWhite-btn {
  position: relative;
  font-size: 1.3em;
  font-weight: 400;
  text-transform: uppercase;
  text-decoration: none;
  color: #000;
  padding: 10px 5px;
}

.blkTextBgWhite-btn .btn-text {
  display: inline-block;
}

.blkTextBgWhite-btn:hover {
  font-weight: bold;
}

.buckets-btn {
  position: relative;
  padding: 10px 30px;
  max-width: 220px;
  margin: 0 auto;
}

.buckets-btn::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: white;
  transform: skewX(-25deg);
}

a.trending-btn.btnTwo {
  padding: 10px 15px;
}

@media (max-width: 680px) {
  .text__bucket {
    text-align: center;
    margin-top: 60px;
    margin-bottom: 60px;
  }
  .text__bucket .image-text__text h2 {
    font-size: 40px;
  }
  .text__bucket .image-text__text p {
    font-size: 16px;
    margin-top: 25px;
    color: white;
  }
  .buckets-btn {
    position: relative;
    padding: 10px 30px;
    margin-top: 30px;
  }
  .buckets-btn::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: white;
    transform: skewX(-25deg);
  }
  .home-wrap .bar {
    font-size: 16px;
  }
  .home-wrap .bar > span {
    font-size: 36px;
  }
}

.home-articles {
  max-width: unset;
  margin: auto;
  margin-bottom: 60px;
}

.home-articles__inner {
  margin-bottom: 40px;
}

.home-articles__more {
  display: none;
}

.home-article__img {
  margin-bottom: 0px;
}

.home-article__header {
  font-weight: bold;
}

.home-article__text p {
  font-size: 12px;
}

.home-article__text .news-button-container {
  display: none;
}

.header--home {
  color: #000;
  font-size: 45px;
  font-family: "Playfair Display", sans-serif;
  font-style: normal;
  font-weight: 700;
  text-align: center;
  text-transform: capitalize;
}

.home-articles__list {
  float: none;
  display: grid;
  grid-template-columns: auto auto auto;
  column-gap: 20px;
  width: 66.66666%;
  max-width: 1000px;
  margin: 0 auto;
  padding-right: unset;
  padding-inline-start: 0px;
  grid-template-columns: 33.33% 33.33% 33.33%;
}

.home-articles__list .home-articles__item {
  width: 100%;
  padding: unset;
  margin-top: 0;
  margin-bottom: unset;
  background: #fff;
}

.home-articles__list .home-articles__item:first-child, .home-articles__list .home-articles__item:nth-child(2) {
  margin-bottom: 20px;
}

.home-article {
  width: 100%;
  height: auto;
}

.home-article__title {
  color: #000;
  font-family: "Lato", sans-serif;
  font-size: 15px;
  font-style: normal;
  text-transform: capitalize;
  font-weight: 700;
  text-align: center;
}

.home-article__teaser {
  display: none;
}

.person-icon {
  padding-right: 5px;
}

@media (max-width: 1000px) {
  .home-article__text {
    margin-top: 5px;
  }
  .home-articles__list {
    grid-template-columns: auto;
  }
  .home-article__img img {
    width: 100%;
    height: auto;
  }
  .home-articles__item:nth-child(3) {
    margin-bottom: 20px;
  }
}

@media (max-width: 768px) {
  .home-articles__list {
    grid-template-columns: auto;
  }
  .home-articles__item:first-child, .home-articles__item:nth-child(2) {
    margin-bottom: 60px;
  }
  .home-articles__item:nth-child(5) {
    margin-bottom: 20px;
  }
}

@media (max-width: 480px) {
  .banner--slider .slidewrap a:after,
  .slidewrap a:after {
    left: 100px;
  }
  .slidewrap a:hover:after {
    left: 100px;
    padding: 10px 20px;
  }
  .banner--slider .slidewrap a:hover:after {
    top: -23px;
  }
  .home-article:hover .article--animation:after {
    left: 0;
  }
}

@media (max-width: 380px) {
  .slidewrap a:after {
    padding: 10px;
    left: 28px;
    font-size: 14px;
  }
  .slidewrap a:hover:after {
    left: 28px;
    padding: 10px;
    font-size: 14px;
  }
  .banner--slider .slidewrap a:after {
    padding: 10px;
    left: 40px;
    font-size: 14px;
  }
  .banner--slider .slidewrap a:hover:after {
    top: -20px;
    font-size: 14px;
  }
}

.site-nav {
  background-color: #fff;
  border-right: 1px solid #a39fa0;
  border-bottom: none;
  font-family: "Lato", sans-serif;
  font-weight: 700;
}

.sub-nav a {
  font-family: "Lato", sans-serif;
}

@media only screen and (min-width: 820px) {
  .site-nav {
    background: none;
    border-color: #a39fa0;
    border-width: 0 0 1px 0;
    border-style: solid;
    font-size: 15px;
  }
  .site-nav__item--featured .site-nav__top {
    color: #fff;
    background-color: #fd0065;
  }
  .site-nav__item--holiday .site-nav__top {
    color: #fff;
    background-color: #5970f6;
  }
  .page--default .site-nav {
    border-bottom: none;
  }
  .site-nav__list {
    margin: 0;
    text-align: left;
  }
  .site-nav__top {
    margin: 0px 4px 0 0;
    color: #fff;
    padding: 10px 10px;
    border-radius: 0;
  }
  .site-nav__item:hover .site-nav__top,
  .faux-hover .site-nav__top {
    color: #fff;
    background: #fd0065;
  }
  .sub-nav {
    min-width: 200px;
    padding: 0;
    margin-top: 0;
    background: #fff;
    border-top: 2px solid #fd0065;
    border-radius: 0;
  }
  .sub-nav__inner {
    padding: 0 15px;
    border-radius: 0;
    border: 1px solid #ddd;
    border-top: none;
  }
  .sub-nav a {
    display: block;
    padding: 8px 5px;
    font-size: 1.15em;
    text-transform: uppercase;
    font-weight: 400;
  }
  .sub-nav .sub-nav__back {
    display: none;
  }
  .sub-nav--big .sub-nav__col {
    max-height: 540px;
  }
}

.sub-nav__col {
  width: 208px;
}

@media only screen and (min-width: 1000px) {
  .sub-nav--big {
    max-width: 1000px;
  }
}

@media only screen and (min-width: 1250px) {
  .site-nav {
    max-width: 1200px;
  }
  .site-nav__top {
    padding: 10px 15px;
  }
}

@media only screen and (min-width: 1400px) {
  .sub-nav--big {
    max-width: 1250px;
  }
  .sub-nav--big .sub-nav__col {
    max-height: 500px;
  }
}

.menu-wrap {
  background-color: #000;
}

.menu-wrap .search {
  display: none;
}

.nav-toggles,
.nav-toggle {
  background-color: #fff;
  justify-self: center;
}

.burger {
  width: 30px;
  height: 23px;
}

.burger__middle {
  background: #000;
  top: 10px;
}

.burger::before,
.burger::after {
  background: #000;
}

@media only screen and (max-width: 620px) {
  .menu-wrap {
    border: 0px;
    display: grid;
    grid-template-columns: 1fr 4fr;
    padding: 10px 0;
    background-color: #fff;
  }
  .menu-wrap .search {
    display: flex;
  }
}

/*--- Login Redesign Styles ---*/
.login {
  color: black;
}

.login-form a {
  color: black;
}

.login-form__actions a {
  background-color: black;
  color: #fff;
}

.login-section__actions a {
  background-color: black;
}

.login-section__header {
  font-size: 24px;
}

/*--- End Login Redesign Styles ---*/
.sample-featured {
  background-color: #dee2fd;
}

.sample__add-more {
  display: none;
}

@media only screen and (min-width: 800px) {
  .mp-checkout__summary {
    top: 230px;
  }
}

/*--- Product Styles --- */
.product__name,
.header-wrap {
  font-style: normal;
}

.product__name {
  font-size: 1em;
}

.prod-info__name,
.prod-info__brand {
  color: #000;
}

.header-wrap h1 {
  font-weight: 700;
  font-family: "Playfair Display", sans-serif;
  font-size: 1.25em;
}

@media only screen and (min-width: 640px) {
  .header-wrap {
    font-size: 30px;
  }
}

.filters__link {
  color: #000;
  font-style: normal;
  font-weight: 400;
}

.filters__header {
  font-family: "Lato", sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  background-color: #000;
}

.for-price,
.for-price--large {
  color: #000;
}

.accordion__toggle {
  font-family: "Lato", sans-serif;
}

.list-link {
  color: #000;
}

.deal-sku__link {
  color: #5970f6;
}

.price__label--highlight {
  background-color: #e80602;
}

/*--- Page Content Redesign Styles ---*/
.contact__item .button {
  background: #000;
  color: #fff;
  text-transform: lowercase;
}

.contact-grid {
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-gap: 40px;
}

.contact-fix-cell {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.span-2-contact {
  -ms-grid-column: 1;
  -ms-grid-column-span: 2;
  grid-column: 1 / span 2;
}

.contact {
  background: #ebebeb;
  color: black;
  padding: 40px 80px;
}

.contact-item {
  font-size: 22px;
}

.contact-header {
  text-align: left;
  font-weight: 700;
  border-bottom: 0px;
  font-size: 24px;
  margin: 10px 0;
}

.contact-img {
  text-align: center;
  margin-top: 40px;
}

.contact-box {
  margin: 20px 0;
  display: flex;
  justify-content: left;
  align-items: left;
  flex-direction: column;
}

.contact-us__sidebar.push-6 {
  padding-top: 0px;
}

.last-header {
  border-bottom: none;
  margin: 0;
}

.post__link {
  font-weight: bold;
}

.post__more {
  position: static;
}

.post__text {
  border-bottom: none;
}

.page--locator-default .header-wrap {
  display: none;
}

.locator-banner-container {
  text-align: center;
}

.locator {
  max-width: 960px;
  margin: 15px;
}

.page-title {
  font-family: "Playfair Display", sans-serif;
  font-weight: 700;
  font-size: 45px;
  text-align: center;
  margin-bottom: 20px;
}

@media (max-width: 819.98px) {
  .page-title {
    font-size: 40px;
  }
}

@media (max-width: 768px) {
  .contact-grid {
    grid-template-columns: auto;
    margin: auto;
    max-width: 600px;
  }
  .contact {
    padding: 40px 50px;
  }
}

/*--- End Page Content Redesign Styles ---*/
.mobile-opt-in {
  margin: 5px 0 15px;
}

.mobile-opt-in__yesno {
  display: block;
  margin: 5px 0;
}

.mobile-opt-in__options {
  display: none;
  margin-top: 5px;
  padding-left: 1.5em;
}

.mobile-opt-in__option {
  display: block;
  margin-top: 5px;
}

.mobile-opt-in__options.is--active {
  display: block;
}

.register .mobile-opt-in {
  padding: 10px 0;
}

.home-wrap .bar {
  max-width: 1000px;
  margin: 60px auto 15px;
  color: #000;
  padding-top: 0;
  font-size: 20px;
  text-align: center;
  background-color: transparent;
}

.home-wrap .bar > span {
  color: #000;
  font-size: 45px;
  font-family: "Playfair Display", sans-serif;
  font-style: normal;
  font-weight: 700;
  text-transform: capitalize;
}

@media (max-width: 680px) {
  .home-wrap .bar > span {
    font-size: 36px;
  }
  .home-wrap .bar {
    font-size: 16px;
  }
}

.slider-outer {
  padding-bottom: 80px;
  padding-top: 60px;
}

.protector-container {
  margin: 20px auto 0;
  padding: 60px;
  color: #000;
  text-align: center;
}

.protector-container + br {
  display: none;
}

.purple-text {
  color: #5970f6;
  font-weight: 700;
}

.protector-tagline {
  margin: 12px auto;
  /*font-family: 'acumin-pro-condensed', sans-serif;*/
  font-size: 46px;
  font-style: normal;
  font-weight: 700;
}

.protector-text {
  max-width: 1000px;
  margin: auto;
  margin-bottom: 30px;
  /*font-family: 'acumin-pro-condensed', sans-serif;*/
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
}

@media screen and (max-width: 768px) {
  .protector-tagline {
    font-size: 26px;
  }
  .protector-text {
    width: auto;
  }
}

.dashboard {
  color: black;
}

.myaccount-nav__link {
  background-color: #000;
}

.samples__toggle {
  background: none;
  background-color: #000;
  color: #fff;
}

.samples__toggle--svg-container {
  background-color: #000;
}

@media only screen and (min-width: 1000px) {
  .slider--bestseller .bx-viewport .slidewrap {
    overflow-y: visible;
  }
  .slider--bestseller .bx-viewport .slidewrap a {
    display: block;
    text-align: center;
    text-decoration: none;
  }
  .slider--bestseller .bx-viewport .slidewrap a::after {
    content: "Shop Now";
    position: relative;
    top: -0.5em;
    display: inline-block;
    margin: 0 auto;
    padding: 10px 20px;
    font-size: 20px;
    font-style: normal;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    opacity: 0;
    color: #fff;
    background-color: black;
    border: none;
    transition: opacity 0.4s, transform 0.4s;
    transform: translateY(0);
    white-space: nowrap;
  }
  .slider--bestseller .bx-viewport .slidewrap a .slider-caption {
    transition: opacity 0.3s;
  }
  .slider--bestseller .bx-viewport .slidewrap a:hover::after {
    opacity: 1;
    text-decoration: none;
    transform: translateY(-0.5em);
  }
  .slider--bestseller .bx-viewport .slidewrap a:hover .slider-caption {
    opacity: 0;
    text-decoration: none;
  }
  .slider--bestseller .bx-wrapper {
    margin-bottom: 15px;
  }
  .slider-caption {
    font-size: 15px;
    color: #000;
    font-family: "Lato", sans-serif;
  }
  .slider--featured .bx-viewport .slidewrap a:after {
    content: "SHOP NOW";
    position: relative;
    top: auto;
    left: 5px !important;
    bottom: 30px !important;
    right: 0;
    padding: 10px 20px;
    font-size: 20px;
    text-align: center;
    color: #fff;
    background-color: black;
    border: none;
    font-style: normal !important;
  }
  .home-article a:after {
    content: none;
  }
  .home-article .home-article__link {
    position: relative;
  }
  .home-article .home-article__text {
    opacity: 1;
    transition: opacity 0.5s;
    margin-top: -20px;
  }
  .home-article .article--animation:after {
    content: "READ HERE";
    display: inline-block;
    position: relative;
    padding: 10px 20px;
    transition: bottom 0.5s, opacity 0.5s;
    text-align: center;
    font-size: 20px;
    color: #fff;
    background-color: black;
    border: none;
    top: 0.5em;
    left: 50%;
    transform: translate(-50%, 20%);
    opacity: 0;
  }
  .home-article:hover .article--animation:after {
    top: auto;
    bottom: 0;
    opacity: 1;
  }
  .home-article:hover .home-article__text {
    opacity: 0;
  }
}

@media only screen and (max-width: 499px) {
  .home-article .article--animation:after {
    content: none;
  }
}

.post__title {
  font-size: 20px;
}

.post__title a {
  color: #000;
  font-style: normal;
  font-weight: 700;
}

.post__teaser {
  font-size: 16px;
}

.post__body {
  font-size: 16px;
}

.post__body h3 {
  font-size: 20px;
  font-weight: 700;
}

.blog-column__link {
  font-style: normal;
  font-weight: 400;
}

.cloud {
  font-style: normal;
  font-weight: 700;
}

/* video gallery */
.videos {
  padding-top: 45px;
}

.video-thumb {
  position: relative;
}

.video-thumb__teaser {
  color: #333;
}

.video-thumb__image {
  display: block;
  width: 100%;
  height: auto;
}

.video-categories {
  padding-top: 45px;
  text-align: center;
}

.video-category__link {
  padding: 10px 20px;
  color: #414042;
  font-size: 16px;
  text-decoration: none;
  text-transform: lowercase;
  border: 1px solid #414042;
}

.video-category__link--active {
  color: #fff;
  background: #414042;
}

.video-category__link:hover {
  text-decoration: none;
}

.video-thumb__link:hover {
  text-decoration: none;
}

.video-thumb__link:hover .video-thumb__title {
  text-decoration: underline;
}

.video-thumb__link:hover .video-thumb__image, .video-thumb--active .video-thumb__image {
  filter: grayscale(0);
}

.video-thumb__title {
  margin-top: 10px;
  margin-bottom: 5px;
  padding: 0;
  font-size: 1.35em;
  color: #000;
}

@media only screen and (min-width: 800px) {
  .videos__thumbs {
    margin: 0 -15px;
    padding: 30px 0;
    overflow: hidden;
  }
  .video-thumb {
    float: left;
    width: 33.3333333%;
    padding: 0 15px;
    margin-bottom: 30px;
    height: 250px;
  }
  .video-thumb__image {
    height: 176px;
  }
  .video-thumb:nth-child(3n+1) {
    clear: both;
  }
  .video-thumb__teaser {
    right: 15px;
    left: 15px;
  }
}

@media (max-width: 768px) {
  .video-categories {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  .video-category {
    margin: 20px 0;
  }
}

.brand-section {
  max-width: 1000px;
  margin: 40px auto;
  margin-bottom: 4em;
}

.brandS-title {
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 0.7em !important;
}

.brandS-btn {
  text-decoration: none;
  padding: 10px 24px;
  border: 2px solid #000;
  border-radius: 6px;
  font-style: normal;
  color: #000;
  font-size: 2em;
}

.brandS-text {
  font-size: 2.5em;
  margin-top: 40px;
}

.brandS-headline {
  font-size: 1.3em;
  line-height: 1.6;
}

.text-pad {
  padding: 0 50px !important;
}

.bgpink {
  background-color: #ffeff7;
}

.bs-bg-box.bgpink {
  background-color: #ffeff7;
}

.vid-con.bgpink {
  background-color: #ffeff7;
}

img.resource-imgs-bx3 {
  width: 100%;
}

.txt-title {
  font-weight: 700;
}

.brandS-mainImg {
  display: flex;
  justify-content: center;
  background-color: #4e4e4e1a;
  height: 450px;
}

.btm-h {
  height: 300px;
}

.brandS-logo {
  text-align: center;
  align-self: center;
}

.brandS-logo .logo {
  font-size: 3em;
  margin-bottom: 25px;
  color: #fff;
}

.brandS-model-image {
  display: flex;
  justify-content: center;
  max-width: 1000px;
  margin: 4em auto;
}

.brandS-model-image .bs-bg-box {
  position: relative;
  background-color: #4e4e4e1a;
}

.brandS-model-image .bs-bg-box .box-img {
  position: relative;
  right: 20px;
  bottom: 20px;
}

.brandS-model-image .bs-bg-box .box-img .bx-innerImg {
  position: relative;
}

.brandS-hl1 {
  position: relative;
}

.brandS-hl1 .left-quote::before {
  content: open-quote;
  position: absolute;
  font-size: 9em;
  top: 50px;
  left: -20px;
}

.brandS-hl1 .right-quote::after {
  content: close-quote;
  position: absolute;
  right: 0;
  font-size: 9em;
  right: -10px;
  bottom: -120px;
}

.left-quote-color::before {
  color: #f2c6db;
  content: open-quote;
  position: absolute;
  font-size: 9em;
  top: 50px;
  left: -20px;
}

.right-quote-color::after {
  color: #f2c6db;
  content: close-quote;
  position: absolute;
  right: 0;
  font-size: 9em;
  right: -10px;
  bottom: -120px;
}

.brandS-hl2 .hlt1 {
  margin-top: 50px;
}

.brandS-hl2 .hlt1 p.headline-text.t1 {
  padding-left: 50px;
}

.brandS-hl2 .hlt2 {
  margin-top: 50px;
}

.brandS-hl2 .hlt2 p.headline-text.t2 {
  padding-right: 50px;
}

.brandS-bgCon {
  background-color: #4e4e4e1a;
}

.brandS-bgCon .bs-res {
  max-width: 1000px;
  margin: 40px auto;
  padding: 80px 0 100px;
}

.bx-center {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50%;
  height: 160px;
}

.bs-textBtn {
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: 0;
}

.bs-btn-text {
  font-weight: 600;
  font-size: 1.2em;
}

.bs-resBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 65%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin: 0;
}

.bs-resBtn a {
  border: 1px solid;
  padding: 3px 12px;
  border-radius: 8px;
}

.bs-resBtn.btnt {
  top: 68%;
}

section.brandS-bgCon.bgpink {
  background-color: #ffeff7;
  overflow: hidden;
}

.bs-textBtn.bs-bx3-center {
  right: 50px;
}

.video-section .vid-con {
  padding-top: 20px;
  background-color: #4e4e4e1a;
}

.video-section .vid-con .video-placeholder {
  bottom: 20px;
  right: 20px;
}

.video-section .vid-con .video-placeholder .video-placeholder__image {
  margin-top: 0px !important;
}

.video-section .vid-con .video-placeholder::before {
  padding-bottom: 45%;
}

@media (min-width: 992px) {
  .bs-textBtn.bs-bx3-center {
    top: 50px !important;
    left: 50px !important;
  }
  .mg-top {
    margin-top: 20px !important;
  }
  .bs-mgt-text {
    margin-top: 50px;
  }
}

@media (min-width: 768.02px) {
  .brandS-title {
    font-size: 3.8em !important;
  }
  .mg-btm {
    margin-bottom: 15px;
  }
  .bx3 {
    width: 25.33333% !important;
  }
  .posi-left {
    top: 50%;
    left: 12%;
  }
  .bx2 {
    width: 25%;
  }
  .posi-left2 {
    top: 50%;
    left: 25%;
    margin-top: 15px;
  }
  .posi-left3 {
    margin-top: 15px;
    top: 50%;
    left: 37%;
  }
}

@media (max-width: 767.98px) {
  .posi-left3 {
    margin-top: 15px;
    left: 25%;
  }
  .posi-left2 {
    margin-top: 15px;
  }
  .srbx3 {
    margin-top: 15px;
    left: 23%;
  }
  .bx12 {
    width: 51%;
  }
  .mgTop {
    margin-top: 15px;
  }
  .brandS-mainImg {
    height: 250px;
  }
  .brandS-model-image {
    padding: 0 20px 0 30px;
  }
  .brandS-model-image .bs-bg-box .box-img {
    position: relative;
    right: 10px;
    bottom: 10px;
  }
  .brandS-hl1 {
    position: relative;
  }
  .brandS-hl1 .left-quote::before {
    content: open-quote;
    position: absolute;
    font-size: 5em;
    top: 55px;
    left: 10px;
  }
  .brandS-hl1 .right-quote::after {
    content: close-quote;
    position: absolute;
    font-size: 5em;
    right: 10px;
    bottom: -70px;
  }
  .brandS-hl2 .hlt1 p.headline-text.t1 {
    padding-left: 0px;
    font-size: 1.2em;
  }
  .brandS-hl2 .hlt2 {
    margin-top: 0px;
  }
  .brandS-hl2 .hlt2 .hltbxx1 {
    grid-column: 1/3;
  }
  .brandS-hl2 .hlt2 .hltbx2 {
    grid-column: 1/3;
    grid-row: 1/1;
    margin-bottom: 20px;
  }
  .brandS-hl2 .hlt2 p.headline-text.t2 {
    padding-right: 0px;
  }
  .hltg .hltgbx1 {
    grid-column: 1/3;
  }
  .hltg .hltgbx2 {
    grid-column: 1/3;
    grid-row: 1/1;
    margin-bottom: 20px;
  }
  .htlmg {
    margin-bottom: 20px;
  }
  .brandS-title {
    font-size: 2em !important;
    font-weight: 600 !important;
    padding: 10px 0 !important;
  }
  .brandS-headline {
    line-height: 1.4;
  }
  .brand-section {
    max-width: 1000px;
    margin: 40px auto;
    margin-bottom: 4em;
  }
  .brandS-btn {
    font-size: 1.2em;
  }
  .brandS-text {
    font-size: 1em;
  }
  .brandS-bgCon {
    background-color: #4e4e4e1a;
    overflow: hidden;
  }
  .video-section {
    padding: 0 20px 0 30px;
  }
  .video-section .vid-con {
    background-color: #4e4e4e1a;
  }
  .video-section .vid-con .video-placeholder {
    bottom: 10px;
    right: 10px;
  }
  .video-section .vid-con .video-placeholder .video-placeholder__image {
    margin-top: 0px;
  }
  .video-section .vid-con .video-placeholder::before {
    padding-bottom: 45%;
  }
  .resource.bs-res-top.sec-left {
    margin-bottom: 20px;
    position: relative;
    left: 0px;
  }
  .bs-textBtn.bs-bx3-center {
    font-size: 1.5em;
    text-align: center;
    top: 20px;
    right: 120px;
  }
  .brandS-hl2 .hlt2 {
    display: block;
    margin-top: 0;
  }
  .btm-h {
    height: 140px !important;
  }
}

@media (max-width: 575.98px) {
  .brandS-hl2 .hlt1 p.headline-text.t1 {
    padding-left: 0px;
    font-size: 1.2em;
  }
  .brandS-hl2 .hlt2 {
    display: grid;
    margin-top: 0px;
  }
  .brandS-hl2 .hlt2 .hltbxx1 {
    grid-column: 1/3;
  }
  .brandS-hl2 .hlt2 .hltbx2 {
    grid-column: 1/3;
    grid-row: 1/1;
    margin-bottom: 20px;
  }
  .brandS-hl2 .hlt2 p.headline-text.t2 {
    padding-right: 0px;
  }
  .brandS-bgCon {
    background-color: #4e4e4e1a;
  }
  .brandS-bgCon .bs-res {
    padding: 30px 0 20px;
  }
  .brandS-hl2 .hlt2 {
    display: grid;
    margin-top: 0;
  }
  .bs-resBtn a {
    padding: 3px 5px;
    border-radius: 8px;
  }
}

.sustain-header {
  line-height: 1;
}

.intro-header, .intro-offers {
  /*font-family: 'acumin-pro-condensed', sans-serif;*/
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
}

.sustain-box {
  line-height: 1.5;
}

.page-img-btns {
  padding: 60px 60px 80px;
  margin: 50px auto 0;
}

.page-img-btns .page-title {
  font-family: "Playfair Display", sans-serif;
  font-weight: 700;
  text-align: center;
  margin-bottom: 20px;
}

.page-img-btns .btn-img-container {
  margin-top: 40px;
}

.page-img-btn {
  margin: 0px auto;
}

.page-img-btn:hover {
  opacity: 0.75;
  transition: opacity 0.5s;
}

@media (max-width: 768px) {
  .page-img-btn:first-of-type {
    margin-bottom: 20px;
  }
}

/* ---- Landing Global ------*/
.page--landing-default .landing {
  padding-top: 0px;
  font-size: 16px;
}

.page--landing-default .landing ul {
  margin: 0;
}

.page--landing-default .landing h2,
.page--landing-default .landing h3,
.page--landing-default .landing h4 {
  font-family: "Playfair Display", sans-serif;
  font-weight: 700;
}

.page--landing-default .landing__body {
  padding: 0px;
}

.page--landing-default .landing-container {
  max-width: 1280px;
  margin: 0 auto;
}

.page--landing-default .landing__title {
  font-family: "Playfair Display", sans-serif;
  font-weight: 700;
  font-size: 45px;
  margin-bottom: 20px;
  text-align: center;
  text-transform: capitalize;
}

.page--landing-default .page-img-btns {
  background-color: #000;
  color: #fff;
  max-width: 1280px;
  margin-left: auto;
  margin-right: auto;
}

.page--landing-default .button__wrapper {
  max-width: 250px;
}

.page--landing-default .button__link {
  font-size: 1.3em;
  text-decoration: none;
}

.landing__header,
.landing__teaser,
.landing__c-to-a {
  display: none;
}

.landing-boxes {
  margin-top: 80px;
}

/*----- About Us Landing -----*/
.landing-about-us p {
  text-align: center;
  font-size: 18px;
}

.landing-about-us > p {
  font-size: 24px;
}

.landing-about-us img {
  margin: 0 auto;
}

.landing-about-us h2 {
  text-align: center;
}

.landing .landing-about-us h2 {
  font-size: 24px;
  margin: 25px auto 10px;
}

.landing .landing-about-us h2:first-of-type {
  margin-top: 25px;
}

.landing-about-us.landing-container {
  margin: 60px auto;
}

/*----- Careers Landing -----*/
.careers-body {
  max-width: 1000px;
  margin: 0 auto;
}

.landing .careers-body p {
  text-align: center;
  margin: 0 40px 30px;
}

.landing .careers-body p.landing-box__disclaimer {
  margin: 20px 0 0;
  color: #fff;
  text-align: left;
}

.careers-body .g-5 {
  padding: 0 40px;
}

.careers-body .g-7 {
  padding: 0px;
}

.careers-body .landing-boxes:first-of-type {
  margin-top: 60px;
  margin-bottom: -20px;
}

.careers-body .landing-box__bg {
  background: black;
  color: #fff;
  padding: 80px 40px;
}

.careers-body .landing-box__bg h3 {
  font-family: "Lato", sans-serif;
  font-size: 30px;
  margin: 0px;
  width: fit-content;
}

.careers-body .landing-box__bg h3::after {
  content: '';
  display: block;
  border-top: 1px solid white;
  margin-top: 8px;
}

.careers-body .landing-box__bg hr {
  background: #fff;
}

.careers-body .landing-box__bg li {
  padding: 5px 0 0 15px;
}

.careers-body .landing-box__bg ul {
  padding-top: 10px;
  padding-left: 20px;
}

.careers-body .join-team {
  text-align: center;
  margin-bottom: 80px;
}

.careers-body .join-team h4 {
  font-size: 20px;
  font-weight: 700;
  font-family: "Lato", sans-serif;
}

.careers-body .join-team p {
  font-family: "Playfair Display", sans-serif;
  font-size: 42px;
  font-weight: 700;
}

/*----- SSPRO Landing -----*/
.landing-sspro-experience > div {
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
}

.landing-sspro-experience .landing-top-banner {
  max-width: inherit;
}

.landing-sspro-experience .landing-box__bg h3 {
  font-size: 24px;
  font-family: "Lato", sans-serif;
}

.landing-sspro-experience .landing-box__bg p {
  line-height: 25px;
}

/*----- WHO WE ARE Landing ------*/
.landing-who-we-are .page-img-btns {
  margin-top: 0px;
}

.landing .landing-who-we-are h2,
.landing .landing-who-we-are h3 {
  font-size: 42px;
  margin-top: 0px;
  margin-bottom: 20px;
}

.landing .landing-who-we-are p.inner-box-title {
  margin-bottom: 10px;
}

.landing-who-we-are h3 {
  text-align: center;
}

.landing-who-we-are .landing-boxes:first-of-type {
  max-width: 1000px;
  margin: 30px auto 0;
  padding: 0 40px;
}

.landing-who-we-are .top-text {
  margin-top: 30px;
}

.landing-who-we-are .top-text .title-top {
  font-size: 20px;
  font-family: "Lato", sans-serif;
  font-weight: 700;
  margin-bottom: 0px;
}

.landing-who-we-are .top-text span {
  font-weight: 700;
}

.landing-who-we-are .box-blk-bg {
  background-color: #000;
  color: #fff;
  padding: 0 60px 80px;
  margin: 160px auto 0;
}

.landing-who-we-are .box-blk-bg .row {
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
}

.landing-who-we-are .box-blk-bg .box-img {
  margin-top: -50%;
}

.landing-who-we-are .box-blk-bg .outer-box {
  display: grid;
  grid-template-columns: auto 75%;
  justify-items: end;
}

.landing-who-we-are .box-blk-bg .outer-box img {
  max-height: 70px;
  grid-row: 1;
}

.landing-who-we-are .box-blk-bg .inner-box {
  padding: 0 0 20px 20px;
  grid-row: 1;
}

.landing-who-we-are .box-blk-bg h3 {
  padding-top: 40px;
}

.landing-who-we-are .landing-timeline {
  background-image: url("/content/themes/salonservices/images/landing/bg-color.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  padding: 60px 0;
  color: #fff;
  background: linear-gradient(180deg, #fd0065 15%, #ff00fe 34%, #5970f6 100%);
}

.landing-who-we-are .landing-timeline h3 {
  margin-top: 0px;
  margin-bottom: 60px;
}

.landing-who-we-are .landing-timeline .timeline-title {
  font-size: 20px;
  margin: 0;
  text-align: center;
}

.landing-who-we-are .landing-timeline .timeline-boxes {
  margin-top: 20px;
}

.landing-who-we-are .landing-timeline .timeline-box__right p,
.landing-who-we-are .landing-timeline .timeline-box__left p {
  margin-bottom: 10px;
}

.landing-who-we-are .landing-timeline .timeline-box__right p span,
.landing-who-we-are .landing-timeline .timeline-box__left p span {
  font-size: 20px;
  font-weight: 700;
}

.landing-who-we-are .landing-timeline .timeline-bottom-title {
  font-weight: 700;
  margin-top: 30px;
  font-family: "Playfair Display", sans-serif;
  text-align: left;
}

@media (min-width: 480px) and (max-width: 680px) {
  .landing-who-we-are .box-blk-bg .box-img {
    margin-top: 0;
  }
}

@media (min-width: 680.02px) {
  .landing-sspro-experience .landing-boxes {
    display: flex;
    align-items: center;
  }
  .landing-sspro-experience .landing-box__bg {
    padding: 0 20px;
  }
  .landing-who-we-are .top-text {
    margin-top: 0px;
  }
  .landing-who-we-are .landing-boxes:first-of-type {
    margin-top: 80px;
    display: flex;
    align-items: center;
  }
  .landing-who-we-are .box-blk-bg h3 {
    text-align: left;
    margin-top: 30%;
  }
  .landing-who-we-are .box-blk-bg .box-img {
    margin-top: -30%;
  }
  .landing-who-we-are .box-blk-bg .inner-box {
    padding-right: 20px;
  }
  .landing-who-we-are .box-blk-body {
    margin-top: 60px;
  }
  .timeline-container .row {
    display: flex;
    align-items: center;
  }
  .careers-body .landing-boxes {
    display: flex;
    align-items: center;
  }
  .careers-body .landing-boxes:first-of-type,
  .careers-body .landing-boxes.join-team {
    display: block;
  }
  .careers-body .g-5 {
    padding: 0;
  }
  .careers-body li {
    font-size: 20px;
  }
}

@media (min-width: 680.02px) and (max-width: 768px) {
  .box-blk-body {
    margin-top: 40px;
  }
}

@media (min-width: 768.02px) {
  .landing-box__img-right {
    float: right;
  }
  .landing-box {
    padding: 0px;
  }
  .join-team p {
    font-size: 45px;
  }
  .page-img-btns .page-title {
    font-size: 45px;
  }
  .landing-sspro-experience .landing-box__img,
  .landing-who-we-are .landing-box__img {
    margin: 0 auto;
  }
  .landing-who-we-are .top-text {
    padding: 0px 60px;
    line-height: 25px;
  }
  .landing-who-we-are .box-blk-bg .inner-box .inner-box-title {
    font-weight: 600;
    margin-bottom: 10px;
  }
  .landing-who-we-are .box-blk-bg .inner-box p {
    font-size: 20px;
  }
  .landing-who-we-are .timeline-bottom-title {
    font-size: 45px;
  }
}

@media (min-width: 1000px) {
  .landing .landing-who-we-are h2,
  .landing .landing-who-we-are h3 {
    font-size: 45px;
  }
  .landing-who-we-are .box-blk-bg {
    margin-top: 220px;
  }
  .landing-who-we-are .box-blk-bg .box-img {
    margin-top: -30%;
  }
  .landing-who-we-are .timeline-box__right {
    padding-left: 40px;
  }
  .landing-who-we-are .timeline-box__left {
    padding-left: 40px;
    padding-right: 40px;
  }
  .landing-who-we-are .timeline-bottom-title {
    font-size: 45px;
  }
  .timeline-container {
    width: 950px;
    margin: auto;
  }
}

@media (max-width: 768px) {
  .landing-container .careers-body .landing-box__img-left,
  .landing-container .careers-body .landing-box__img-right {
    margin: 0 auto;
  }
  .landing-container .careers-body .landing-box {
    padding: 0px;
  }
  .landing-container .careers-body .landing-box__bg h3 {
    font-size: 30px;
  }
  .landing-container .landing-about-us img {
    margin-top: 40px;
  }
  .landing-container .landing-box__img-right,
  .landing-container .landing-box__img-left {
    margin: 0 auto;
  }
  .landing-container .page-title {
    font-size: 42px !important;
  }
  .landing-container .page-img-btn {
    margin-bottom: 20px;
  }
  .landing-container .landing-who-we-are .box-blk-bg {
    padding: 60px 40px 80px;
  }
  .landing-container .landing-who-we-are .box-blk-bg img.box-img {
    width: 80%;
    margin: 0 auto;
  }
  .landing-container .landing-who-we-are .outer-box {
    align-items: self-start;
  }
  .landing-container .landing-who-we-are .landing-timeline {
    padding: 40px;
  }
  .landing-container .landing-who-we-are .timeline-bottom-title {
    margin-top: 60px;
    margin-bottom: 30px;
  }
}

@media (max-width: 680px) {
  .landing-sspro-experience .landing-box {
    padding: 0px 40px;
  }
  .landing-sspro-experience .landing-boxes {
    margin-top: 30px;
  }
  .careers-body .landing-box img {
    margin: 0 auto;
  }
  .mobile-grid {
    display: grid;
  }
  .landing-box.mobile-grid-img {
    grid-column: 1/2;
    grid-row: 1/2;
  }
  .number-one {
    grid-column: 1/2;
    grid-row: 1/1;
  }
  .number-two {
    grid-column: 1/2;
    grid-row: 2/2;
  }
  .box-blk-body {
    margin-top: 30px;
  }
  .landing-box__img {
    width: 70%;
    margin: 0 auto;
  }
  .landing-who-we-are .timeline-box__right {
    padding-right: 0 80px;
  }
  .landing-who-we-are .timeline-box__left {
    padding-left: 0 80px;
  }
  .landing-who-we-are .title-img {
    display: grid;
  }
  .landing-who-we-are .inner-box-title {
    font-size: 20px;
  }
}
