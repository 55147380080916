﻿.page-img-btns {
    padding: 60px 60px 80px;
    margin: 50px auto 0;

    & .page-title {
        font-family: $font-header;
        font-weight: 700;
        text-align: center;
        margin-bottom: 20px;
    }

    .btn-img-container {
        margin-top: 40px;
    }
}

.page-img-btn {
    margin: 0px auto;
}

.page-img-btn:hover {
    opacity: 0.75;
    transition: opacity 0.5s;
}

@media (max-width: 768px) {
    .page-img-btn:first-of-type {
        margin-bottom: 20px;
    }
}

/* ---- Landing Global ------*/
.page--landing-default {
    .landing {
        padding-top: 0px;
        font-size: 16px;

        ul {
            margin: 0;
        }

        h2,
        h3,
        h4 {
            font-family: $font-header;
            font-weight: 700;
        }
    }

    .landing__body {
        padding: 0px
    }

    .landing-container {
        max-width: 1280px;
        margin: 0 auto;
    }

    .landing__title {
        font-family: $font-header;
        font-weight: 700;
        font-size: 45px;
        margin-bottom: 20px;
        text-align: center;
        text-transform: capitalize;
    }

    .page-img-btns {
        background-color: #000;
        color: #fff;
        max-width: 1280px;
        margin-left: auto;
        margin-right: auto;
    }

    .button__wrapper {
        max-width: 250px;
    }

    .button__link {
        font-size: 1.3em;
        text-decoration: none;
    }
}

.landing__header,
.landing__teaser,
.landing__c-to-a {
    display: none;
}

.landing-boxes {
    margin-top: 80px;
}

/*----- About Us Landing -----*/
.landing-about-us {
    p {
        text-align: center;
        font-size: 18px;
    }

    &>p {
        font-size: 24px;
    }

    img {
        margin: 0 auto;
    }

    h2 {
        text-align: center;
    }

    .landing & {
        h2 {
            font-size: 24px;
            margin: 25px auto 10px;

            &:first-of-type {
                margin-top: 25px;
            }
        }
    }

    &.landing-container {
        margin: 60px auto;
    }
}

/*----- Careers Landing -----*/
.careers-body {
    max-width: 1000px;
    margin: 0 auto;

    .landing & {
        p {
            text-align: center;
            margin: 0 40px 30px;
        }

        p.landing-box__disclaimer {
            margin: 20px 0 0;
            color: #fff;
            text-align: left;
        }
    }

    .g-5 {
        padding: 0 40px;
    }

    .g-7 {
        padding: 0px;
    }

    .landing-boxes:first-of-type {
        margin-top: 60px;
        margin-bottom: -20px;
    }

    .landing-box__bg {
        background: black;
        color: #fff;
        padding: 80px 40px;

        h3 {
            font-family: $font-primary;
            font-size: 30px;
            margin: 0px;
            width: fit-content;

            &::after {
                content: '';
                display: block;
                border-top: 1px solid white;
                margin-top: 8px;
            }
        }

        hr {
            background: #fff;
        }

        li {
            padding: 5px 0 0 15px;
        }

        ul {
            padding-top: 10px;
            padding-left: 20px;
        }
    }

    .join-team {
        text-align: center;
        margin-bottom: 80px;

        h4 {
            font-size: 20px;
            font-weight: 700;
            font-family: $font-primary;
        }

        p {
            font-family: $font-header;
            font-size: 42px;
            font-weight: 700;
        }
    }
}

/*----- SSPRO Landing -----*/
.landing-sspro-experience {
    >div {
        max-width: 1000px;
        margin-left: auto;
        margin-right: auto;
    }

    .landing-top-banner {
        max-width: inherit;
    }

    .landing-box__bg {
        h3 {
            font-size: 24px;
            font-family: $font-primary;
        }

        p {
            line-height: 25px;
        }
    }
}

/*----- WHO WE ARE Landing ------*/
.landing-who-we-are {
    .page-img-btns {
        margin-top: 0px;
    }

    .landing & {

        h2,
        h3 {
            font-size: 42px;
            margin-top: 0px;
            margin-bottom: 20px;
        }

        p.inner-box-title {
            margin-bottom: 10px;
        }
    }

    h3 {
        text-align: center;
    }

    .landing-boxes:first-of-type {
        max-width: 1000px;
        margin: 30px auto 0;
        padding: 0 40px;
    }

    .top-text {
        margin-top: 30px;

        .title-top {
            font-size: 20px;
            font-family: $font-subheader;
            font-weight: 700;
            margin-bottom: 0px;
        }

        span {
            font-weight: 700;
        }
    }

    .box-blk-bg {
        background-color: #000;
        color: #fff;
        padding: 0 60px 80px;
        margin: 160px auto 0;

        .row {
            max-width: 1000px;
            margin-left: auto;
            margin-right: auto;
        }

        .box-img {
            margin-top: -50%;
        }

        .outer-box {
            display: grid;
            grid-template-columns: auto 75%;
            justify-items: end;

            img {
                max-height: 70px;
                grid-row: 1;
            }
        }

        .inner-box {
            padding: 0 0 20px 20px;
            grid-row: 1;
        }

        h3 {
            padding-top: 40px;
        }
    }

    .landing-timeline {
        background-image: url("/content/themes/salonservices/images/landing/bg-color.jpg");
        background-repeat: no-repeat;
        background-size: cover;
        padding: 60px 0;
        color: #fff;
        background: linear-gradient(180deg, #fd0065 15%, #ff00fe 34%, #5970f6 100%);

        h3 {
            margin-top: 0px;
            margin-bottom: 60px;
        }

        .timeline-title {
            font-size: 20px;
            margin: 0;
            text-align: center;
        }

        .timeline-boxes {
            margin-top: 20px;
        }

        .timeline-box__right,
        .timeline-box__left {
            p {
                margin-bottom: 10px;

                span {
                    font-size: 20px;
                    font-weight: 700;
                }
            }
        }

        .timeline-bottom-title {
            font-weight: 700;
            margin-top: 30px;
            font-family: $font-header;
            text-align: left;
        }
    }
}

@media (min-width: 480px) and (max-width: 680px) {
    .landing-who-we-are {
        .box-blk-bg .box-img {
            margin-top: 0;
        }
    }
}

@media (min-width: 680.02px) {
    .landing-sspro-experience {
        .landing-boxes {
            display: flex;
            align-items: center;
        }

        .landing-box__bg {
            padding: 0 20px;
        }
    }

    .landing-who-we-are {
        .top-text {
            margin-top: 0px;
        }

        .landing-boxes:first-of-type {
            margin-top: 80px;
            display: flex;
            align-items: center;
        }

        .box-blk-bg {
            h3 {
                text-align: left;
                margin-top: 30%;
            }

            .box-img {
                margin-top: -30%;
            }

            .inner-box {
                padding-right: 20px;
            }
        }

        .box-blk-body {
            margin-top: 60px;
        }
    }

    .timeline-container {
        .row {
            display: flex;
            align-items: center;
        }
    }

    .careers-body {
        .landing-boxes {
            display: flex;
            align-items: center;
        }

        .landing-boxes:first-of-type,
        .landing-boxes.join-team {
            display: block;
        }

        .g-5 {
            padding: 0;
        }

        li {
            font-size: 20px;
        }
    }
}

@media (min-width: 680.02px) and (max-width: 768px) {
    .box-blk-body {
        margin-top: 40px;
    }
}

@media (min-width: 768.02px) {
    .landing-box__img-right {
        float: right;
    }

    .landing-box {
        padding: 0px;
    }

    .join-team {
        p {
            font-size: 45px;
        }
    }

    .page-img-btns {
        & .page-title {
            font-size: 45px;
        }
    }

    .landing-sspro-experience,
    .landing-who-we-are {
        .landing-box__img {
            margin: 0 auto;
        }
    }

    .landing-who-we-are {
        .top-text {
            padding: 0px 60px;
            line-height: 25px;
        }

        .box-blk-bg {
            .inner-box {
                .inner-box-title {
                    font-weight: 600;
                    margin-bottom: 10px;
                }

                p {
                    font-size: 20px;
                }
            }
        }

        .timeline-bottom-title {
            font-size: 45px;
        }
    }
}

@media (min-width: 1000px) {
    .landing-who-we-are {
        .landing & {

            h2,
            h3 {
                font-size: 45px;
            }
        }

        .box-blk-bg {
            margin-top: 220px;

            .box-img {
                margin-top: -30%;
            }
        }

        .timeline-box__right {
            padding-left: 40px;
        }

        .timeline-box__left {
            padding-left: 40px;
            padding-right: 40px;
        }

        .timeline-bottom-title {
            font-size: 45px;
        }
    }

    .timeline-container {
        width: 950px;
        margin: auto;
    }
}

@media (max-width: 768px) {
    .landing-container {
        .careers-body {

            .landing-box__img-left,
            .landing-box__img-right {
                margin: 0 auto;
            }

            .landing-box {
                padding: 0px;
            }

            .landing-box__bg {
                h3 {
                    font-size: 30px;
                }
            }
        }

        .landing-about-us img {
            margin-top: 40px;
        }

        .landing-box__img-right,
        .landing-box__img-left {
            margin: 0 auto;
        }

        .page-title {
            font-size: 42px !important;
        }

        .page-img-btn {
            margin-bottom: 20px;
        }

        .landing-who-we-are {
            .box-blk-bg {
                padding: 60px 40px 80px;

                img.box-img {
                    width: 80%;
                    margin: 0 auto;
                }
            }

            .outer-box {
                align-items: self-start;
            }

            .landing-timeline {
                padding: 40px;
            }

            .timeline-bottom-title {
                margin-top: 60px;
                margin-bottom: 30px;
            }
        }
    }
}

@media (max-width: 680px) {
    .landing-sspro-experience {
        .landing-box {
            padding: 0px 40px;
        }

        .landing-boxes {
            margin-top: 30px;
        }
    }

    .careers-body {
        .landing-box img {
            margin: 0 auto;
        }
    }

    .mobile-grid {
        display: grid;
    }

    .landing-box.mobile-grid-img {
        grid-column: 1/2;
        grid-row: 1/2;
    }

    .number-one {
        grid-column: 1/2;
        grid-row: 1/1;
    }

    .number-two {
        grid-column: 1/2;
        grid-row: 2/2;
    }

    .box-blk-body {
        margin-top: 30px;
    }

    .landing-box__img {
        width: 70%;
        margin: 0 auto;
    }

    .landing-who-we-are {
        .timeline-box__right {
            padding-right: 0 80px;
        }

        .timeline-box__left {
            padding-left: 0 80px;
        }

        .title-img {
            display: grid;
        }

        .inner-box-title {
            font-size: 20px;
        }
    }
}