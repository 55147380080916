.home-wrap {
    .bar {
        max-width: 1000px;
        margin: 60px auto 15px;
        color: $color-primary;
        padding-top: 0;
        font-size: 20px;
        text-align: center;
        background-color: transparent;

        & > span {
            color: $color-primary;
            font-size: 45px;
            font-family: $font-header;
            font-style: normal;
            font-weight: 700;
            text-transform: capitalize;
        }
    }
}

@media (max-width: 680px) {    
    .home-wrap .bar > span {
        font-size: 36px;
    }

    .home-wrap .bar {
        font-size: 16px;
    }
}

.slider-outer {    
    padding-bottom: 80px;        
    padding-top: 60px;
}