.sustain-header {
	line-height: 1;
}

.intro-header, .intro-offers {
	/*font-family: 'acumin-pro-condensed', sans-serif;*/
	font-size: 24px;
	font-style: normal;
	font-weight: 700;
}

.sustain-box {
	line-height: 1.5;
}
