.home-articles {
    max-width: unset;
    margin: auto;
    margin-bottom: 60px;
}

.home-articles__inner {
    margin-bottom: 40px;
}

// ----------------------- blog view now btn
.home-articles__more {
    display: none;
}

.home-article__img {
    margin-bottom: 0px;
}

.home-article__header {
    font-weight: bold;
}

.home-article__text {
    p {
        font-size: 12px;
    }

    .news-button-container {
        display: none;
    }
}

.header--home {
    color: $color-primary;
    font-size: 45px;
    font-family: $font-header;
    font-style: normal;
    font-weight: 700;
    text-align: center;
    text-transform: capitalize;
}

.home-articles__list {
    float: none;
    display: grid;
    grid-template-columns: auto auto auto;
    column-gap: 20px;
    width: 66.66666%;
    max-width: 1000px;
    margin: 0 auto;
    padding-right: unset;
    padding-inline-start: 0px;
    grid-template-columns: 33.33% 33.33% 33.33%;

    .home-articles__item {
        width: 100%;
        padding: unset;
        margin-top: 0;
        margin-bottom: unset;
        background: #fff;

        &:first-child,
        &:nth-child(2) {
            margin-bottom: 20px;
        }
    }
}

.home-article {
  width: 100%;
  height: auto;
}

.home-article__title {
    color: $color-primary;
    font-family: $font-subheader;    
    font-size: 15px;
    font-style: normal;
    text-transform: capitalize;
    font-weight: 700;
    text-align: center;
}

.home-article__teaser {
  display: none;
}

.person-icon {
    padding-right: 5px;
}

@media (max-width: 1000px) {
    .home-article__text {
        margin-top: 5px;
    }
    
    .home-articles__list {
        grid-template-columns: auto;
    }

  .home-article__img {
    img {
      width: 100%;
      height: auto;
    }
  }

  .home-articles__item {
    &:nth-child(3) {
      margin-bottom: 20px;
    }
  }
}

@media (max-width: 768px) {
  .home-articles__list {
    grid-template-columns: auto;
  }

  .home-articles__item {
    &:first-child,
    &:nth-child(2) {
      margin-bottom: 60px;
    }
  }

  .home-articles__item {
    &:nth-child(5) {
      margin-bottom: 20px;
    }
  }
}

@media (max-width: 480px) {
  .banner--slider .slidewrap a:after,
  .slidewrap a:after {
    left: 100px;
  }

  .slidewrap a:hover:after {
    left: 100px;
    padding: 10px 20px;
  }

  .banner--slider .slidewrap a:hover:after {
    top: -23px;
  }

  .home-article:hover {
    .article--animation:after {
      left: 0;
    }
  }
}

@media (max-width: 380px) {
  .slidewrap a:after {
    padding: 10px;
    left: 28px;
    font-size: 14px;
  }

  .slidewrap a:hover:after {
    left: 28px;
    padding: 10px;
    font-size: 14px;
  }

  .banner--slider .slidewrap a:after {
    padding: 10px;
    left: 40px;
    font-size: 14px;
  }

  .banner--slider .slidewrap a:hover:after {
    top: -20px;
    font-size: 14px;
  }
}
