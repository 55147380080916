/*--- Page Content Redesign Styles ---*/
.contact__item {
  .button {
    background: #000;
    color: #fff;
    text-transform: lowercase;
  }
}

.contact-grid {
  display: grid;
  grid-template-columns: 2fr 1fr;
  grid-gap: 40px;
}

.contact-fix-cell {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.span-2-contact {
  -ms-grid-column: 1;
  -ms-grid-column-span: 2;
  grid-column: 1 / span 2;
}

.contact {
  background: #ebebeb;
  color: black;
  padding: 40px 80px;
}

.contact-item {
  font-size: 22px;
}

.contact-header {
  text-align: left;
  font-weight: 700;
  border-bottom: 0px;
  font-size: 24px;
  margin: 10px 0;
}

.contact-img {
  text-align: center;
  margin-top: 40px;
}

.contact-box {
  margin: 20px 0;
  display: flex;
  justify-content: left;
  align-items: left;
  flex-direction: column;
}

.contact-us__sidebar.push-6 {
    padding-top: 0px;
}

.last-header {
  border-bottom: none;
  margin: 0;
}

.post__link {
  font-weight: bold;
}

.post__more {
  position: static;
}

.post__text {
  border-bottom: none;
}

.page--locator-default .header-wrap {
    display: none;
}

.locator-banner-container {
  text-align: center;
}

.locator {
  max-width: 960px;
  margin: 15px;
}

.page-title {
    font-family: $font-header;
    font-weight: 700;
    font-size: 45px;
    text-align: center;
    margin-bottom: 20px;
}

@media (max-width: 819.98px) {
  .page-title {
    font-size: 40px;
  }
}

@media (max-width: 768px) {
  .contact-grid {
    grid-template-columns: auto;
    margin: auto;
    max-width: 600px;
  }

  .contact {
    padding: 40px 50px;
  }
}

/*--- End Page Content Redesign Styles ---*/
