.sample-featured {
	background-color: #dee2fd;
}

.sample__add-more {
	display: none;
}

@media only screen and (min-width: 800px) {
	.mp-checkout__summary {
		top: 230px;
	}
}