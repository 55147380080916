/*--- Footer styles ---*/
.site-footer {
    margin-top: auto;
    padding-top: 0;
    background-color: black;

    .g-4 {
        margin: 0 20px;
        width: auto;
    }

    .g-12 {
        padding: 0;
    }

    .google-translate {
        padding: 5px 0;
    }
}

.footer-signup {
    background: $pink;
    padding: 50px 0;
    margin-top: 0;
}

.site-footer__header {
    border-bottom: none;
}

.site-footer,
.site-footer a {
    max-width: 100%;
    padding: 0;
    color: #fff;
}

.site-footer a {
    font-size: 1.1em;
}

.site-footer__copyright {
    margin-top: 0;
    color: #fff;    
    font-style: normal;
    
    small {
        font-size: 13px;
    }
}

.site-footer__logo {
    padding-top: 60px;
}

.site-footer__panel h3 {
    font-size: 1.3em;    
}

@media only screen and (min-width: 620px) {
  .site-footer__logo {
    padding-top: 80px;
  }
}

.site-footer__item .social.list--plain {
  margin-top: 4px;
}

.social__item {
  margin: auto;
  margin-right: 10px;
}

.social__item {
  &:hover {
    .social__link {
      &:first-child {
        margin-top: unset;
      }
    }
  }
}

.i--bangstyle,
.i--facebook,
.i--feed,
.i--instagram,
.i--linkedin,
.i--pinterest,
.i--twitter,
.i--wordpress,
.i--youtube,
.i--tiktok,
.i--email {
    background: url("/content/themes/salonservices/images/social-icons-white.png") no-repeat;
    background-size: 157px
}

.i--facebook {
  background-position: -45px -2px;
}

.i--instagram {
  background-position: -87px -2px;
}

.i--email {
  background-position: -129px -2px;
}

.i--tiktok {
  background-position: -2px -2px;
}

.footer-inner {
    max-width: 1000px;
    margin: 0 auto;
    padding-top: 50px;
}

.footer-copyright {
    display: block;
    margin: 50px auto 0;
    width: 100%;
    background: #fd0d0a;
    background: linear-gradient( to right, #fd0d0a 0%, #fd0065 21%, #ff00fe 41%, #5970f6 63%, #00fa92 100% );
}

@media (max-width: 768px) {
    .site-footer__item .social.list--plain {
        margin-top: 10px;
    }

  .mobile-display {
    display: block;
  }

  .footer-inner {
    padding: 0 10px;
  }

  .footer-column {
    display: grid !important;
    grid-template-columns: auto auto;
  }

  .foot1 {
    grid-column: 1;
    grid-row: 1;
  }

  .foot2 {
    grid-column: 2;
    grid-row: 1 / span 2;
  }

  .site-footer__panel {
    padding-top: 15px;

    h3 {
      font-size: 1em;
    }
  }

  .site-footer {
    margin-top: auto;
  }
}
