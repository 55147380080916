/* fonts */
@import "./styles/fonts";

/* colors */
$pink: #fd0065;
$purple: #970635;
$tan: #e7e1db;
$black: #000;
$blue: #5970f6;

$color-emphasis: $blue;
$color-primary: $black;

$font-primary: "Lato", sans-serif;
$font-subheader: "Lato", sans-serif;
$font-header: "Playfair Display", sans-serif;

$max-content-width: 1600px;

body {
  font-size: 14px;
  font-family: $font-primary;
  font-weight: 400;
  color: $color-primary;
}

a {
  color: $color-emphasis;
}

img {
  max-width: 100%;
  height: auto;
}

// Full width pencil banner
.main-content {
  max-width: none;
}

.main-content__inner > div {
  max-width: 1000px;
  margin: 0 auto;
}

.page--default,
.page--landing-default {
  .main-content__inner {
    > div {
      max-width: none;
    }
  }
}

.has--pencil .pencil,
.has--pencil .pencil-multi {
    max-width: none;
}

.pencil-banner {
  max-width: none;
}

.breadcrumb__help,
.page-help {
    p {
        display: none;
    }
}

.page-header {
    overflow: initial;
}

/* ==== OBJECTS ==== */

/* ---------------------------
#buttons
--------------------------- */

.button {
    color: #fff;
    background: #000;
}

.RadGrid_Silk .rgEditForm .button {
    color: #fff;
}

.button--line {
    color: #7e7e7e;
    background: #fff;
}

.button--line:hover {
    color: #7e7e7e;
    background: #eee;
}

.button--text {
    color: #005ca0;
    background: none;
    border: none;
}

.button--text:hover {
    color: #005ca0;
    background: none;
    text-decoration: underline;
}

.button--quickview {
    background: #000;
    color: #fff;
}

.button--pending {
    &::after {
        background-color: #000;
    }
}

.button__wrapper {
    position: relative;
    padding: 10px;
    max-width: 220px;
    margin: 0 auto;
    text-align: center;

    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        background: #000;
        transform: skewX(-25deg);
    }
}

.button__link {
    position: relative;
    font-size: 1.3em;
    font-weight: 400;
    text-transform: uppercase;
    text-decoration: none;
    color: #fff;
    padding: 10px 30px;

    &:hover {
        font-weight: 700;
    }
}

/* ==== BANNERS ==== */

.standard-banners--secondary .standard-banner__button {
    position: relative;
    padding: 10px 30px;
    left: 0;
    max-width: 235px;
    margin: 30px auto 0;
    text-align: center;
}

.standard-banner__button-text {
    font-weight: 400;
}

.standard-banner__button-text:hover {
    font-weight: bold;
}

.standard-banners--secondary {
    background: #000;
    padding-top: 60px;
    padding-bottom: 60px;
}

.standard-banners--secondary .standard-banners--inner {
    max-width: 1000px;
    margin: 0 auto;
}

.standard-banners--secondary .standard-banner__title {
    color: #fff;
    text-align: center;
    font-size: 28px;
}

.standard-banners--secondary .standard-banner {
    margin: 0 auto;
    padding: 30px 0 30px 10px;
}

.standard-banners--secondary .standard-banner__title h3 {
    font-family: $font-subheader;
    font-weight: 400;
    font-size: 20px;
    text-align: center;
    color: #fff;
    margin-top: 30px;
}

.standard-banners--secondary .standard-banner__button:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: #fff;
    -webkit-transform: skewX(-25deg);
    -ms-transform: skewX(-25deg);
    transform: skewX(-25deg);
}

.standard-banners--secondary .standard-banner__button a {
    position: relative;
    font-size: 1.3em;
    font-weight: 300;
    text-transform: uppercase;
    text-decoration: none;
    color: #000;
    padding: 10px 5px;
}

@media only screen and (max-width: 680px) {
    .standard-banner__image {
        max-width: 80%;
    }
}

/* ---------------------------
#home-page
--------------------------- */
.bx-wrapper {
    .bx-next,
    .bx-prev {
        background: url("/content/themes/salonservices/images/universal-nav-arrows.png");
        width: 36px;
        height: 37px;
    }

    .bx-next {
        background-position: -36px 0;

        &:hover {
            background-position: -36px 0;
        }
    }

    .bx-controls-direction a {
        width: 36px;
        height: 37px;
        opacity: 0.8;
        z-index: 900;
        top: 40%;
    }

    .bx-pager.bx-default-pager a {
        background: #cac8c9;
    }
}

.main-banner-wrap {
    .bx-wrapper {
        margin-bottom: 0px;

        .bx-next {
            display: block;
            right: 5px;
        }

        .bx-prev {
            display: block;
            left: 5px;
        }
    }

    .bx-controls-direction {
        display: block;
    }

    .bx-controls-direction a {
        top: 50%;
    }
}

.bx-wrapper .bx-pager.bx-default-pager a.active,
.bx-wrapper .bx-pager.bx-default-pager a:hover {
    background: $color-emphasis;
}

@media only screen and (max-width: 680px) {
    .standard-banners--secondary .standard-banner__button a {
        font-size: 1em;
    }

    .main-banner-wrap .bx-controls-direction {
        display: none;
    }
}

/* ==== 4: COMPONENTS ==== */

.home-site,
.catalogs {
    max-width: 1000px;
    margin-right: auto;
    margin-left: auto;
}

// --------------------------------

/* ---------------------------
#tabs
--------------------------- */

.tabs-nav .tabs-nav--active {
    background: #89b8aa;
    border-color: #89b8aa;
}

/* ---------------------------
#catalogs
--------------------------- */

.catalogs {
    margin-bottom: 45px;
}

.catalogs__header {
    padding-left: 0;
}

.catalog--primary .catalog__text,
.catalog--secondary .catalog__text {
    color: #980539;
}

.catalog--primary .catalog__text::after,
.catalog--secondary .catalog__text::after {
    background-image: url("/content/themes/salonservices/images/arrow-red.png");
}

/* ---------------------------
#countdown
--------------------------- */

.countdown {
    display: table;
    margin: 0 auto;
    padding: 0 30px 0 15px;
    color: #fff;
    overflow: hidden;
}

.countdown__section {
    float: left;
    margin: 0 2px;
    padding: 5px 2px;
    text-align: center;
}

.countdown__label {
    color: #ccc;
    color: rgba(255, 255, 255, 0.7);
    font-size: 0.8em;
    font-style: italic;
}

.countdown__text {
    display: inline-block;
    padding: 0 0 0 4px;
    font-size: 1.25em;
}

@media only screen and (min-width: 380px) {
    .countdown__text {
        padding-top: 8px;
    }
}

@media only screen and (min-width: 700px) {
    .countdown {
        float: left;
    }

    .countdown__section {
        margin: 0 10px;
        padding: 5px 10px;
    }
}

.icon--store {
    display: inline-block;
    width: 28px;
    height: 24px;
    vertical-align: middle;
    background-image: url("/content/themes/salonservices/images/store-pickup2.png");
    background-repeat: no-repeat;
    background-size: 100%;
    opacity: 0.9;
}

.store-popup__header {
    border-bottom: 5px solid #000;
}

.store-popup__box {
    border: 2px solid #000;
}

.store-popup__close {
    color: $color-primary;
}

.pickup-store--selected {
    color: #000;
    background-color: rgba(234, 99, 145, 0.2);
}

/*--- Quick view Fix ---*/
.ui-dialog {
    .g-4 {
        width: 33.333333%;
        margin: 0 auto;
    }
}
/*--- End Quick view Fix ---*/

// Follow Us
.home-social__inner {
    text-align: center;
    margin-bottom: 40px;

    span {
        color: $color-primary;
        font-size: 45px;
        font-family: $font-header;
        font-style: normal;
        font-weight: 700;
        text-align: center;
        text-transform: capitalize;
    }
}

ul.home-social__list {
    display: inline-flex;
}

.home-social__item .i--facebook,
.home-social__item .i--tiktok,
.home-social__item .i--instagram {
    margin-right: 12px;
    width: 54px;
    height: 54px;
    background: url("/images/socialicons-home-black.png") no-repeat 0 0;
    float: left;
}

.home-social__item .i--instagram {
    background-position: -145px 0;
}

.home-social__item .i--facebook {
    background-position: -72px 0;
}

/* Multi Color Bar*/
.multiColorBar__bar {
    background: #fd0d0a;
    background: linear-gradient( to right, #fd0d0a 0%, #fd0065 21%, #ff00fe 41%, #5970f6 63%, #00fa92 100% );
    border: none;
}

.multiColorBar__text {
    font-weight: 400;
}

#Topic a {
    color: $color-emphasis;
}

@import "./styles/footer";

@import "./styles/headertop";

@import "./styles/home-buckets";

@import "./styles/homearticle";

@import "./styles/nav";

@import "./styles/login";

@import "./styles/shopping-cart";

@import "./styles/product";

@import "./styles/pagecontent";

@import "./styles/mobile-opt-in";

@import "./styles/productscroller";

@import "./styles/herotext";

@import "./styles/dashboard";

@import "./styles/samples-toggle";

@import "./styles/slideranimation";

@import "./styles/blog";

@import "./styles/gallery";

@import "./styles/brand-stories";

@import "./styles/brandpages";

@import "./styles/landing";
