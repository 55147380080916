/*--- Login Redesign Styles ---*/

.login {
  color: black;
}

.login-form {
  a {
    color: black;
  }
}

.login-form__actions {
  a {
    background-color: black;
    color: #fff;
  }
}

.login-section__actions {
  a {
    background-color: black;
  }
}

.login-section__header {
  font-size: 24px;
}

/*--- End Login Redesign Styles ---*/