//---------------------- New global mobile header -----------------//
.site-header {
    background-color: black;
}

.top-header {
    font-size: 15px;
    background: #fd0d0a;
    background: linear-gradient( to right, #fd0d0a 0%, #fd0065 21%, #ff00fe 41%, #5970f6 63%, #00fa92 100% );
}

.top-header__inner {
    display: grid;
    grid-template-columns: 1fr auto;
    max-width: $max-content-width;
    margin: 0 auto;
    align-items: center;

    a {
        color: #000;
    }
}

.header-top__note {
    display: none;
}

.header-top__note--mobile {
    display: none;
}

.header-top__assistance,
.header-top__assistance--mobile {
    display: none;
}

.header-top__locator {
    margin-left: 8px;
    grid-row: 2 / span 2;
    grid-column: 1;

    .is--logged-in & {
        grid-row: 2;
        padding: 5px 0;
    }
}

.shop-location {
    margin-left: 8px;
    padding-bottom: 8px;
    grid-row: 3;
    grid-column: 1;
}

.shop-location__content {
    color: #000;
}

.utility-bar {
    grid-row: 2 / span 2;
    grid-column: 2;
    align-self: center;
    background-color: transparent;
}

.utility-bar__inner {
    grid-template-columns: 1fr auto auto;
    max-width: $max-content-width;
    align-items: center;

    a {
        text-transform: uppercase;
        color: #000;
    }

    .account-header__link {
        color: #fff;
    }

    .login-links__link {
        font-weight: 700;
    }
}

.login-links {
    grid-column: 2;
    text-transform: uppercase;
}

.account-header {
    grid-column: 2;
    background-color: transparent;
    color: #000;
}

.account-header__icon {
    display: block;
    justify-self: right;
    grid-row: 1;
}

.account-header__toggle {
    display: block;
    justify-self: left;
}

.account-header__icon .icon-person .icon-circle {
    fill: none;
    stroke: #000;
    stroke-width: 1px;
}

.account-header__icon .icon-person .icon-body,
.account-header__icon .icon-person .icon-head,
.account-header__toggle {
    fill: #000;
    stroke: #000;
}

.account-header__toggle svg,
.header-cart__icon svg {
    fill: #000;
}

.header-cart__value {
    color: #fff;
}

.account-header__nav {
    background-color: #f5f5f5;

    a {
        color: #000;
    }
}

.account-header__list {
    background-color: #58595b;
}

.header-cart {
    grid-column: 3;
}

.login-links,
.header-cart,
.account-header__account-name,
.header-top__countdown {
    margin-right: 8px;
    color: #000;
}

.header-search {
    grid-column: 2;
    width: 100%;
    max-width: 400px;
    justify-self: center;
}

.search__options {
    display: none;
}

.search,
.search__text {
    max-width: unset;
}

.search {
    border-color: #4c4c4c;
    border-radius: 50px;
}

.search__text {
    border-left: none;
    padding: 0 20px;
}

.search__field {
    height: 35px;
    padding: 10px 10px 7px;
}

.search__field::placeholder {
    color: $color-primary;
    text-transform: uppercase;
    font-size: 15px;
    line-height: 20px;
}

.search__submit {
    flex: 0 0 25px;
}

.button--search {
    width: 20px;
    height: 20px;
    background-size: 20px 20px;
    background: url("/content/themes/salonservices/images/search-black.png") no-repeat 0 0;
}

.header-top__countdown {
    grid-row: 1;
    grid-column: 2;
    width: 100%;
    padding-top: 5px;
    padding-right: 10px;
    max-width: $max-content-width;
    margin: 0 auto;
}

.header-top--added {
    background-color: #338326;
}

.site-logo {    
    grid-column: 1;
    justify-self: start;
}

.site-logo__img {
    width: 300px;
}

.site-logo--mobile {
    display: none;
}

.header-banner {
    display: grid;
    align-items: center;
    max-width: $max-content-width;
    padding: 10px;
    background-color: #000;
}

.header-banner__assistance {
    justify-self: right;
}

.header-top__assistance--mobile a,
.header-banner__assistance a {
    color: #000;
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: center;
}

.account-header__close {
    color: inherit;
}

.site-header .google-translate {
    max-width: $max-content-width;
    padding-left: 5px;
}

@media only screen and (min-width: 680.02px) {
    .header-banner {
        grid-template-columns: 3fr 1fr;
    }
}

@media only screen and (min-width: 820px) {
    .top-header {
        z-index: 9000;
    }

    .title-wrap {
        padding: 0em;
        padding-bottom: 1em;
    }

    .account-header__nav {
        border-top: 1px solid white;
        box-shadow: 3px 3px 8px rgba(0,0,0,.16);
    }

    .header-top__note {
		font-size: 15px;
	}

	.site-header .google-translate {
		padding-top: 45px;

        .is--logged-in & {
            padding-top: 70px;
        }
	}
}

@media only screen and (min-width: 820px) and (min-height: 600px) {
    .fixed-header {
        position: sticky;
        top: 0;
        z-index: 1000;
        background-color: #fff;
    }
}

@media only screen and (max-width: 620px) {
    .site-header {
        background: #fff;
    }

    .top-header__inner {
        grid-template-columns: 1fr;        
    }

    .header-top__locator {
        margin-left: 8px;
        grid-row: 2;
        text-align: center;
        padding: 10px 0;        
    }

    .shop-location {
        margin: 0 auto;
        padding-bottom: 0;
        font-size: 12px;
    }

    .shop-location__message {
        padding: 5px 10px;
    }

    .shop-location__content {
        text-align: left;
    }

    .header-top__countdown {
        grid-row: 5;
        grid-column: 1;
        text-align: center;
        padding: 5px 0;
    }

    .site-logo--mobile {
        grid-row: 6;
        padding: 25px;
        margin: 0 auto;
        display: block;
        grid-column: 1;
        background: #000;
        width: 100%;
        text-align: center;

        .site-logo__img {
            margin: 0 auto;
        }
    }

    .utility-bar {
        grid-row: 7;
        grid-column: 1;
        background: #fff;
        padding: 0px;
    }

    .utility-bar__inner {
        grid-template-columns: 80% 20%;
        justify-items: center;
    }

    .account-header {
        width: 100%;
        grid-column: 1;
        color: #000;
        background-color: #fff;
    }

    .header-cart {
        grid-column: 2;
        justify-self: end;
    }

    .account-header__account-greeting {
        display: none;
    }

    .account-header__header-i-guess {
        display: grid;
        grid-template-columns: auto auto 75%;
        align-items: center;
    }

    .account-header__icon .icon-person {
        grid-column: 1;
        grid-row: 1;

/*        .icon-circle,
        .icon-body,
        .icon-head {
            fill: #fff;
        }*/
    }

    .account-header__toggle {
        grid-column: 2;
        grid-row: 1;
        padding: 5px 10px 0 5px;
    }

    .account-header__title {
        grid-column: 3;
        text-align: left;
    }

    .search {
        display: none;
    }

    .login-links {
        grid-column: 1;
        justify-self: start;
        padding-left: 20px;
    }

    .header-search {
        padding-right: 10px;
        width: 100%;
        grid-column: 2;
    }

    .header-banner {
        display: none;
    }

    .title-wrap,
    .is--logged-in .title-wrap {
        padding: 0px;
    }
}
//---------------------- End new mobile header -----------------//

