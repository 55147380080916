.post__title {
  font-size: 20px;

  a {
    color: $black;    
    font-style: normal;
    font-weight: 700;
  }
}

.post__teaser {
  font-size: 16px;
}

.post__body {
    font-size: 16px;

    h3 {
        font-size: 20px;
        font-weight: 700;
    }
}

.blog-column__link {
  font-style: normal;
  font-weight: 400;
}

.cloud {
  font-style: normal;
  font-weight: 700;
}